import React from "react";
import "./Footer.css";
import logo from "../../assets/images/logo.png"; // Replace with your logo URL or path

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Fetch the current year dynamically

  return (
    <footer className="footer">
      {/* Logo and Tagline Section */}
      <div className="footer-logo-section">
        <img src={logo} alt="iLearnPlace Logo" className="footer-logo" />
        <div className="vertical-line"></div> {/* Vertical Line */}
        <div className="footer-tagline">
          <h3>ILP</h3>
          <p>Where Fun Meets Knowledge</p>
        </div>
      </div>

      {/* Links Section */}
      <div className="footer-links">
        <a href="/rcp">Return & Cancellation</a>
        <a href="/sdp">Shipping & Delivery</a>
        <a href="/pp">Privacy Policy</a>
        <a href="/tc">Terms & Conditions</a>
      </div>

      {/* Copyright Section */}
      <div className="footer-copyright">
        <p>© {currentYear} iLearnPlace</p> {/* Dynamically fetch the year */}
      </div>
    </footer>
  );
};

export default Footer;
