// src/redux/actions.js
import { createOrderRequest, createOrderSuccess, createOrderFailure } from '../../features/products/razorpaySlice';

export const createRazorpayOrder = (product_code, token, totalPayable, address, couponName) => async (dispatch) => {
  // Log the arguments before hitting the API
  console.log("API Call Arguments:", {
    product_code,
    token,
    totalPayable,
    address,
    couponName,
  });

  dispatch(createOrderRequest());

  try {
    const response = await fetch('https://almondai.in/ilpapi/createorder/', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_code,
        totalPayable,
        address,
        couponName,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("API Response Data:", data);  // Log the response to see the response data
      dispatch(createOrderSuccess(data));
      return data;  // Return the data here
    } else {
      console.error("API Error:", data.message || 'Failed to create order'); // Log the error message
      dispatch(createOrderFailure(data.message || 'Failed to create order'));
      return { error: true, message: data.message }; // Return an error object if failure
    }
  } catch (error) {
    console.error("API Exception:", error.message || 'Error creating order'); // Log the exception
    dispatch(createOrderFailure(error.message || 'Error creating order'));
    return { error: true, message: error.message }; // Return an error object if catch block is triggered
  }
};
