// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import "./Games.css";
// import SuperSaverButton from "./SuperSaverButton";
// import Card from "../Card/Card"; // Assuming Card component exists
// import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
// import { Navigation } from "swiper/modules"; // Import Navigation module
// import "swiper/css"; // Import Swiper core styles
// import "swiper/css/navigation"; // Import Swiper navigation styles
// import { fetchPromotedLevels } from "../../features/products/promotedLevelsSlice"; // Import Redux action
// import BannerSlider from "./BannerSlider";
// import ThemeSwitcher from "./ThemeSwitcher";
// import { useParams, useNavigate } from "react-router-dom";
// import robo from "../../assets/images/robocar.png"; // Replace with your image path
// import { Typography } from "@mui/material"; // Add this line to import Typography
// import decryptURI from "../modifier/decryptURI"

// const Games = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate(); // For navigation
  
//   // Fetch the promoted levels data from Redux state
//   const { levels, status, error } = useSelector((state) => state.promotedLevels);
//   const handleTryNow = (levelCode) => {
//     navigate(`/tasks/${levelCode}`); // Navigate to tasks page
//   };

//   const handleBuyNow = (levelCode) => {
//     // Dispatch an action to initiate purchase
//     // dispatch(fetchProductDetails(levelCode));
//     navigate(`/stem-learning-kit/${levelCode}`); // Navigate to purchase page
//     // console.log(levelCode)  
//   };
//   useEffect(() => {
//     // Dispatch the action to fetch the data
//     dispatch(fetchPromotedLevels());
//   }, [dispatch]);

//   if (status === "loading") {
//     return <Typography>Loading promoted levels...</Typography>;
//   }

//   if (status === "failed" || error) {
//     return <Typography>Error: {error}</Typography>;
//   }

//   return (
//     <section className="games" id="games">
//       <div className="games-container">
//         {/* Heading Button */}
//         <SuperSaverButton />

//         {/* Horizontal Card Swiper Section */}
//         <Swiper
//           spaceBetween={20} // Space between slides
//           slidesPerView={1} // Default: 1 slide per view
//           breakpoints={{
//             768: { slidesPerView: 2 }, // 2 cards on tablets
//             1024: { slidesPerView: 3 }, // 3 cards on desktops
//           }}
//           navigation={false} // Enables navigation buttons
//           modules={[Navigation]} // Include Navigation module
//         >
//           {levels.map((level, index) => (
            
//             <SwiperSlide key={index}>
//               <Card
//                 title={level.level_name}
//                 description={level.level_title}
//                 currentPrice= {`Rs. ${Math.round(level.price - (level.price * (level.discount || 0)) / 100)}`}
//                 // oldPrice={`Rs. ${levels.price - (levels.price * (levels.discount || 0)) / 100
//                 // }`}
//                 oldPrice= {`Rs. ${level.price}`}
//                 rating={4.8}
//                 // const currentPrice = Math.round(
//                 //   levels.price - (levels.price * (levels.discount || 0)) / 100
//                 // );
//                 image={decryptURI(level.photo)} // You can dynamically set the image here
//                 onTryNow={() => handleTryNow(level.level_code)} // Pass level_code to handler
//                 onBuyNow={() => handleBuyNow(level.level_code)} // Pass level_code for purchase
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//         <BannerSlider />
//         <ThemeSwitcher promotedLevels={levels} />
//       </div>
//     </section>
//   );
// };

// export default Games;







import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Games.css";
import SuperSaverButton from "./SuperSaverButton";
import Card from "../Card/Card"; // Assuming Card component exists
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import { Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css"; // Import Swiper core styles
import { fetchPromotedLevels } from "../../features/products/promotedLevelsSlice"; // Import Redux action
import BannerSlider from "./BannerSlider";
import ThemeSwitcher from "./ThemeSwitcher";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material"; // Add this line to import Typography
import decryptURI from "../modifier/decryptURI";

const Games = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For navigation
  
  // Fetch the promoted levels data from Redux state
  const { levels, status, error } = useSelector((state) => state.promotedLevels);

  const handleTryNow = (levelCode) => {
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');
    navigate(`/tasks/${levelNameWithHyphens}`,{state:{
      levelName: levelCode.level_name, 
      levelCode: levelCode.level_code
  }}); // Navigate to tasks page
  };

  const handleBuyNow = (levelCode) => {
    // Dispatch an action to initiate purchase
    // dispatch(fetchProductDetails(levelCode.level_code));
    
    // Replace spaces with hyphens in level_name
    console.log(levelCode.level_name)
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');

    // Navigate to purchase page with modified levelName
    navigate(`/stem-learning-kit/${levelNameWithHyphens}`, {
        state: {
            levelName: levelCode.level_name, 
            levelCode: levelCode.level_code
        }
    });
};

  useEffect(() => {
    // Dispatch the action to fetch the data
    dispatch(fetchPromotedLevels());
  }, [dispatch]);

  if (status === "loading") {
    return <Typography>Loading promoted levels...</Typography>;
  }

  if (status === "failed" || error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <section className="games" id="games">
      <div className="games-container">
        {/* Heading Button */}
        <SuperSaverButton />

        {/* Horizontal Card Swiper Section */}
        <Swiper
          spaceBetween={20} // Space between slides
          slidesPerView={1} // Default: 1 slide per view
          breakpoints={{
            768: { slidesPerView: 2 }, // 2 cards on tablets
            1024: { slidesPerView: 3 }, // 3 cards on desktops
          }}
          autoplay={{
            delay: 3000, // Time in milliseconds between slides (3 seconds)
            disableOnInteraction: false, // Allow autoplay to continue even if user interacts
          }}
          modules={[Autoplay]} // Include Autoplay module
          loop={true} // Enable infinite looping
        >
          {levels.map((level, index) => (
            <SwiperSlide key={index}>
              <Card
                title={level.level_name}
                description={level.level_title}
                currentPrice={`Rs. ${Math.round(level.price - (level.price * (level.discount || 0)) / 100)}`}
                oldPrice={`Rs. ${level.price}`}
                rating={4.8}
                image={decryptURI(level.photo)} // Dynamically set the image
                onTryNow={() => handleTryNow(level)} // Pass level_code to handler
                onBuyNow={() => handleBuyNow(level)} // Pass level_code for purchase
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <BannerSlider />
        <ThemeSwitcher promotedLevels={levels} />
      </div>
    </section>
  );
};

export default Games;
