// import React, { useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./FunZoneButton.css";

// const FunZoneButton = () => {
//   const navigate = useNavigate(); // Hook to programmatically navigate
//   const location = useLocation(); // Get the current route
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const isHomePage = location.pathname === "/"; // Check if the current route is home

//   const handleButtonClick = () => {
//     navigate(isHomePage ? "/dashboard" : "/"); // Redirect based on the current route
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <button className="fun-zone-button" onClick={handleButtonClick}>
//       {isHomePage ? "Dashboard" : "Home"}
//     </button>
//   );
// };

// export default FunZoneButton;


import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./FunZoneButton.css";

const FunZoneButton = ({ onClick }) => {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation(); // Get the current route

  const isHomePage = location.pathname === "/"; // Check if the current route is home

  const handleNavigate = () => {
    if (onClick) onClick(); // Close the menu if provided
    navigate(isHomePage ? "/dashboard" : "/"); // Redirect based on the current route
  };

  return (
    <button className="fun-zone-button" onClick={handleNavigate}>
      {isHomePage ? "Dashboard" : "Home"}
    </button>
  );
};

export default FunZoneButton;
