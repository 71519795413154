// import React, { useState } from "react";
// import { Link } from "react-scroll";
// import logo from "../../assets/images/logo.png"; // Import the logo
// import "./Navbar.css";

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <nav className="navbar">
//       {/* Logo */}
//       <div className="navbar-left">
//         <img src={logo} alt="Logo" className="logo" />
//       </div>

//       {/* Mobile Menu Toggle Button */}
//       <button className="menu-toggle" onClick={toggleMenu}>
//         ☰
//       </button>

//       {/* Navigation Menu (Responsive for Desktop and Mobile) */}
//       <div className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
//         <ul className="nav-links">
//           <li>
//             <Link to="home" smooth={true} duration={500} onClick={toggleMenu}>
//               Home
//             </Link>
//           </li>
//           <li>
//             <Link to="games" smooth={true} duration={500} onClick={toggleMenu}>
//               Games
//             </Link>
//           </li>
//           <li>
//             <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
//               About Us
//             </Link>
//           </li>
//           <li>
//             <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
//               Contact Us
//             </Link>
//           </li>
//         </ul>

//         {/* Fun Zone and Login Buttons */}
//         <div className="navbar-buttons">
//           <button className="fun-zone">Fun Zone</button>
//           <button className="login">Login</button>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


// import React, { useState, useEffect } from "react";
// import { Link } from "react-scroll";
// import logo from "../../assets/images/logo.png"; // Import the logo
// import "./Navbar.css";

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isNavbarVisible, setIsNavbarVisible] = useState(true);
//   let lastScrollY = 0;

//   // Toggle mobile menu
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   // Hide/Show Navbar on Scroll
//   const handleScroll = () => {
//     if (window.scrollY > lastScrollY) {
//       setIsNavbarVisible(false); // Hide Navbar on scroll down
//     } else {
//       setIsNavbarVisible(true); // Show Navbar on scroll up
//     }
//     lastScrollY = window.scrollY;
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <nav className={`navbar ${isNavbarVisible ? "" : "hidden"}`}>
//       {/* Logo */}
//       <div className="navbar-left">
//         <img src={logo} alt="Logo" className="logo" />
//       </div>

//       {/* Mobile Menu Toggle Button */}
//       <button className="menu-toggle" onClick={toggleMenu}>
//         ☰
//       </button>

//       {/* Navigation Menu */}
//       <div className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
//         <ul className="nav-links">
//           <li>
//             <Link to="home" smooth={true} duration={500} onClick={toggleMenu}>
//               Home
//             </Link>
//           </li>
//           <li>
//             <Link to="games" smooth={true} duration={500} onClick={toggleMenu}>
//               Games
//             </Link>
//           </li>
//           <li>
//             <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
//               About Us
//             </Link>
//           </li>
//           <li>
//             <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
//               Contact Us
//             </Link>
//           </li>
//         </ul>

//         {/* Buttons */}
//         <div className="navbar-buttons">
//           <button className="fun-zone">Fun Zone</button>
//           <button className="login">Login</button>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


// import React, { useState } from "react";
// import { Link } from "react-scroll";
// import { useLocation } from "react-router-dom"; // Import useLocation
// import logo from "../../assets/images/logo.png"; // Import your logo
// import FunZoneButton from "../Buttons/FunZoneButton";
// import LoginButton from "../Buttons/LoginButton";
// import "./Navbar.css";

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const location = useLocation(); // Get the current route

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };
  

//   const isHomePage = location.pathname === "/"; // Check if the current route is home page

//   return (
//     <nav className="navbar">
//       <div className="navbar-left">
//         <img src={logo} alt="Logo" className="logo" />
//       </div>

//       <button className="menu-toggle" onClick={toggleMenu}>
//         ☰
//       </button>

//       <div className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
//         {isHomePage && ( // Conditionally render the navigation links
//           <ul className="nav-links">
//             <li>
//               <Link to="home" smooth={true} duration={500} onClick={toggleMenu}>
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="games" smooth={true} duration={500} onClick={toggleMenu}>
//                 Games
//               </Link>
//             </li>
//             <li>
//               <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
//                 Contact Us
//               </Link>
//             </li>
//           </ul>
//         )}

//         {/* Use Button Components */}
//         <div className="navbar-buttons">
//           <FunZoneButton />
//           <LoginButton />
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;




import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/ilearnplace_logo.png";
import FunZoneButton from "../Buttons/FunZoneButton";
import LoginButton from "../Buttons/LoginButton";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navMenuRef = useRef(null); // Ref for nav menu
  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // React Router's navigation hook

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Close the menu if clicked outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleLogoClick = () => {
    navigate("/"); // Redirect to the home page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Ensure the menu closes
  };

  const isHomePage = location.pathname === "/"; // Check if the current route is home page

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>

      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>

      <div
        ref={navMenuRef}
        className={`nav-menu ${isMenuOpen ? "show" : ""}`}
      >
        {isHomePage && ( // Conditionally render the navigation links
          <ul className="nav-links">
            <li>
              <Link to="home" smooth={true} duration={500} onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="games" smooth={true} duration={500} onClick={closeMenu}>
                Games
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} duration={500} onClick={closeMenu}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={500} onClick={closeMenu}>
                Contact Us
              </Link>
            </li>
          </ul>
        )}

        {/* Use Button Components */}
        <div className="navbar-buttons">
          <FunZoneButton onClick={closeMenu} /> {/* Pass closeMenu */}
          <LoginButton onClick={closeMenu} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;



// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-scroll";
// import {  useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import logo from "../../assets/images/ilearnplace_logo.png";
// import FunZoneButton from "../Buttons/FunZoneButton";
// import LoginButton from "../Buttons/LoginButton";
// import "./Navbar.css";

// const Navbar = () => {
//   const navigate = useNavigate(); // React Router's navigation hook
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const location = useLocation(); // Get the current route
//   const navMenuRef = useRef(null); // Reference to the nav menu

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false); // Ensure the menu closes
//   };

//   const handleLogoClick = () => {
//     navigate("/"); // Redirect to the home page
//   };

//   // Handle clicking outside the nav menu
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
//         closeMenu(); // Close the menu if clicked outside
//       }
//     };

//     if (isMenuOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isMenuOpen]);

//   const isHomePage = location.pathname === "/"; // Check if the current route is home page

//   return (
//     <nav className="navbar">
//       <div className="navbar-left">
//         <img src={logo} alt="Logo" className="logo"  onClick={handleLogoClick}/>
//       </div>

//       <button className="menu-toggle" onClick={toggleMenu}>
//         ☰
//       </button>

//       <div
//         ref={navMenuRef} // Attach ref to the nav menu
//         className={`nav-menu ${isMenuOpen ? "show" : ""}`}
//       >
//         {isHomePage && ( // Conditionally render the navigation links
//           <ul className="nav-links">
//             <li>
//               <Link to="home" smooth={true} duration={500} onClick={closeMenu}>
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="games" smooth={true} duration={500} onClick={closeMenu}>
//                 Games
//               </Link>
//             </li>
//             <li>
//               <Link to="about" smooth={true} duration={500} onClick={closeMenu}>
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link to="contact" smooth={true} duration={500} onClick={closeMenu}>
//                 Contact Us
//               </Link>
//             </li>
//           </ul>
//         )}

//         {/* Use Button Components */}
//         <div className="navbar-buttons">
//           <FunZoneButton onClick={closeMenu} />
//           <LoginButton onClick={closeMenu} />
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
