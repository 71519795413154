import React, { useState } from "react";
import "./TestimonialsPage.css";
import ag from "../../assets/images/Mr. Ashok Gupta.webp"; // Replace with your actual image path
import as from "../../assets/images/Mr. Ashok Sharma.webp"; // Replace with your actual image path
import vc from "../../assets/images/Mr. Vikash Chodhary.webp"; // Replace with your actual image path
import hg from "../../assets/images/Mrs. Hemlata Gulati.webp"; // Replace with your actual image path

const testimonials = [
  {
    id: 1,
    name: "Ashok Gupta",
    image: ag,
    review:
      "iLearnPlace has transformed how our students engage with STEM concepts, making learning both fun and practical.",
    rating: 5,
    reviewsCount: "12 reviews at Yelp",
  },
  {
    id: 2,
    name: "Ashok Sharma",
    image: as,
    review:
      "The STEM kits and lab sessions from iLearnPlace have sparked curiosity and innovation among our students.",
    rating: 4,
    reviewsCount: "8 reviews at Google",
  },
  {
    id: 3,
    name: "Vikash Chodhary ",
    image: vc,
    review:
      "Teachers and students alike praise iLearnPlace for bringing theoretical knowledge to life.",
    rating: 4,
    reviewsCount: "8 reviews at Google",
  },
  {
    id: 4,
    name: "Hemalata Gulati",
    image: hg,
    review:
      "Integrating iLearnPlace’s resources into our curriculum has been a game-changer for STEM education.",
    rating: 4,
    reviewsCount: "8 reviews at Google",
  },
  
];

const TestimonialsPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">Testimonials</h2>

      <div className="testimonials-wrapper">
        {/* Left Text Section */}
        <div className="testimonials-text">
          <h3>What They Say</h3>
          <p>
          At iLearnPlace, we value the feedback of our students and parents. Their experiences inspire us to continue creating impactful learning journeys. Here’s what they have to say about our STEM and robotics programs.
          </p>
          {/* <p>
            Some of the students and parents were greatly satisfied by our
            products.
          </p>
          <p>Are you too? Please give your assessment.</p> */}
        </div>

        {/* Right Image and Card Section */}
        <div className="testimonials-image-wrapper">
          <img
            src={currentTestimonial.image}
            alt={currentTestimonial.name}
            className="testimonials-image"
          />
          <div className="testimonials-card">
            <p className="testimonials-review">"{currentTestimonial.review}"</p>
            <p className="testimonials-name">{currentTestimonial.name}</p>
            {/* <p className="testimonials-reviews">{currentTestimonial.reviewsCount}</p> */}
            {/* <div className="testimonials-rating">
              {"⭐".repeat(currentTestimonial.rating)}
            </div> */}
          </div>
          {/* <button
            className="arrow-button arrow-left"
            onClick={handlePrevious}
          >&#8592;</button> */}
          <button
            className="arrow-button arrow-right"
            onClick={handleNext}
          >&#8594;</button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsPage;
