


// import { createSlice } from '@reduxjs/toolkit';

// export const taskDetailsSlice = createSlice({
//   name: 'taskDetails',
//   initialState: {
//     task: null,
//     loading: false,
//     error: null,
//   },
//   reducers: {
//     taskDetailsRequest: (state) => {
//       console.log("taskdet",state)
//       state.loading = true;
//     },
//     taskDetailsSuccess: (state, action) => {
//       state.loading = false;
//       state.task = action.payload;
//     },
//     taskDetailsFailure: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//   },
// });

// export const { taskDetailsRequest, taskDetailsSuccess, taskDetailsFailure } = taskDetailsSlice.actions;

// // Redux thunk action to fetch task details based on task_code and token
// export const fetchTaskDetails = (taskCode, token) => async (dispatch) => {
//   try {
//     dispatch(taskDetailsRequest());
    
//     // Check if token is provided
//     if (!token) {
//     //   console.log('No token found. Redirecting to login...');
//       return; // Exit if token is missing (you can also add redirection here)
//     }

//     // Fetch the task details from the API
//     const response = await fetch(`https://almondai.in/ilpapi/api/tasks/${taskCode}/`, {
//       method: 'GET',
//       headers: {
//         'Authorization': token, // Pass JWT token in the header
//         'Content-Type': 'application/json',
//       },
//     });

//     // Handle invalid response
//     if (!response.ok) {
//       throw new Error(`Error fetching task details: ${response.statusText}`);
//     }

//     // Parse the JSON data
//     const data = await response.json();
//     if (data.message) {
//       // Handle token validation errors or missing task details
//       throw new Error(data.message);
//     }

//     // Dispatch the data if successful
//     dispatch(taskDetailsSuccess(data));
    
//   } catch (error) {
//     // Dispatch failure action if any error occurs
//     dispatch(taskDetailsFailure(error.message));
//   }
// };

// export default taskDetailsSlice.reducer;








import { createSlice } from '@reduxjs/toolkit';

export const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState: {
    task: {}, // Default to an empty object
    loading: false,
    error: null,
  },
  reducers: {
    taskDetailsRequest: (state) => {
      state.loading = true;
      state.error = null; // Clear any previous error
    },
    taskDetailsSuccess: (state, action) => {
      state.loading = false;
      state.task = action.payload;
    },
    taskDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { taskDetailsRequest, taskDetailsSuccess, taskDetailsFailure } = taskDetailsSlice.actions;

// Fetch task details thunk
export const fetchTaskDetails = (taskCode) => async (dispatch) => {
  try {
    dispatch(taskDetailsRequest());

    // Retrieve token from localStorage
    const token = localStorage.getItem('token');

    // Check if token exists
    if (!token) {
      dispatch(taskDetailsFailure("Authentication token is missing."));
      return;
    }

    const response = await fetch(`https://almondai.in/ilpapi/api/tasks/${taskCode}/`, {
      method: 'GET',
      headers: {
        'Authorization': token, // Use the token from localStorage
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text(); // Fetch detailed error response
      throw new Error(`Error fetching task details: ${response.status} - ${errorText}`);
    }

    const data = await response.json();

    if (data.message) {
      throw new Error(data.message);
    }

    if (!data || Object.keys(data).length === 0) {
      throw new Error("No task details found.");
    }

    dispatch(taskDetailsSuccess(data));

  } catch (error) {
    dispatch(taskDetailsFailure(error.message));
  }
};

export default taskDetailsSlice.reducer;
