// src/utils/decryptURI.js

import CryptoJS from "crypto-js";

const decryptURI = (encryptedData) => {
  const base64Key = "jW1DMN6Gd62k4pQdssZBG0y1M7SldkWZTYIEENToswE=";
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));

  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  return CryptoJS.enc.Utf8.stringify(decryptedBytes);
};

export default decryptURI;
