// src/redux/razorpaySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  razorpayOrderId: null,
  paymentStatus: null,
  loading: false,
  error: null,
};

const razorpaySlice = createSlice({
  name: 'razorpay',
  initialState,
  reducers: {
    createOrderRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createOrderSuccess(state, action) {
      state.loading = false;
      state.razorpayOrderId = action.payload.razorpay_order_id;
    },
    createOrderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    verifyPaymentSuccess(state, action) {
      state.paymentStatus = 'success';
    },
    verifyPaymentFailure(state, action) {
      state.paymentStatus = 'failure';
    },
  },
});

export const {
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  verifyPaymentSuccess,
  verifyPaymentFailure,
} = razorpaySlice.actions;

export default razorpaySlice.reducer;
