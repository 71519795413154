import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

// Root styling
const Root = styled("div")(() => ({
  width: "100%",
  maxWidth: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      {/* Centered Heading */}
      <Typography
        variant="h1"
        gutterBottom
        style={{
          fontSize: "2.5rem",
          textAlign: "center",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
        }}
      >
        PRIVACY POLICY
      </Typography>
      <Container maxWidth="md">
        {/* Content */}
        <Typography variant="body1" gutterBottom>
          This privacy policy sets out how Almondai Group - Ilearnplace (“ILP - Group”)
          uses and protects any information that you give us when you use this networking platform (“ILP - Group Community”) on
          the website www.ilearnplace.com.
        </Typography>

        <Typography variant="body1" gutterBottom>
          This Privacy Policy is intended to explain what data we collect from Users of the website. A “User” means either a member of the ILP - Group Community or a non-Member who is simply visiting the Website. Our Service is intended for Users over seven (7) years of age, and we will not intentionally collect or maintain information about anyone under seven (7) years of age.
        </Typography>

        <Typography variant="body1" gutterBottom>
          ILP - Group Community provides a social networking platform where learners and other users of the website of Almondai Group - Ilearnplace can interact and follow each other on the platform, share ideas and concepts, and participate in social interactions with each other (“Services”). Almondai Group - Ilearnplace is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this privacy statement.
        </Typography>

        <Typography variant="body1" gutterBottom>
          ILP - Group may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>LOG FILES</b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will be collecting non-personally identifiable information. Like many other websites, ILP - Group makes use of log files. The information inside the log files includes internet protocol (IP) addresses, type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and the number of clicks to analyze trends, administer the site, track users' movement around the site, and gather demographic information.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>COLLECTION OF YOUR INFORMATION</b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may collect the following information:
        </Typography>
        <ul>
          <li>Name</li>
          <li>Contact information including email address</li>
          <li>Demographic information such as gender, postcode, preferences, and interests</li>
          <li>Profile photo</li>
          <li>Username or Login IDs on other platforms such as Facebook and Google</li>
        </ul>

        <Typography variant="body1" gutterBottom>
          ILP - Group may share your information with its business partners, third-party customers, suppliers, or service providers. These entities will be contractually obligated to keep your information confidential and secure, using it only for the promised services.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>COOKIES AND WEB BEACONS</b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          ILP - Group does use cookies to store information about visitors' preferences, record user-specific information on which pages the user accesses or visits, and customize website page content based on visitors' browser type or other information.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>SAFETY AND SECURITY</b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use the information to help verify accounts and activity to promote safety and security on and off our services. We use commercially reasonable administrative, technical, personnel, and physical measures to safeguard your personal information.
        </Typography>

        <Typography variant="body1" gutterBottom>
          If you have any grievances or queries regarding this Privacy Policy, you may contact us at:
          <br />
          <b>Email:</b> feedback@ilearnplace.com <br />
          <b>Phone:</b> +91 809 441 4990
        </Typography>
      </Container>
    </Root>
  );
};

export default PrivacyPolicy;
