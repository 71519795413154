// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { fetchProductsByGameCode } from "../../features/products/productsSlice";
// import Card from "../Card/Card"; // Use your reusable card component
// import "./ProductPage.css";
// import vid from "../../assets/videos/mov_bbb.mp4";
// import robo from "../../assets/images/robocar.png";
// import mili from "../../assets/images/baner1.png";

// // Map gameCode to video and overlay image
// const mediaMap = {
//   TBF: {
//     video: vid, // Replace with actual video path
//     image: robo, // Replace with actual overlay image path
//   },
//   TSC: {
//     video: vid,
//     image: mili,
//   },
//   default: {
//     video: vid,
//     image: robo,
//   },
// };

// const ProductPage = () => {
//   const { gameCode } = useParams(); // Extract gameCode from URL
//   const dispatch = useDispatch();
//   const { products, status, error } = useSelector((state) => state.products);

//   const videoRef = useRef(null); // Video reference for play/pause
//   const [isPlaying, setIsPlaying] = useState(true); // Track play/pause state

//   useEffect(() => {
//     dispatch(fetchProductsByGameCode(gameCode)); // Fetch products by gameCode
//   }, [dispatch, gameCode]);

//   const togglePlayPause = () => {
//     if (videoRef.current) {
//       if (isPlaying) {
//         videoRef.current.pause();
//       } else {
//         videoRef.current.play();
//       }
//       setIsPlaying(!isPlaying);
//     }
//   };

//   // Ensure case-insensitive matching for gameCode
//   const { video, image } = mediaMap[gameCode?.toUpperCase()] || mediaMap.default;

//   if (status === "loading") return <p>Loading products...</p>;
//   if (status === "failed") return <p>Error: {error}</p>;

//   return (
//     <div className="product-page">
//       {/* Video Section */}
//       <div className="video-container" onClick={togglePlayPause}>
//         <video
//           ref={videoRef}
//           className="background-video"
//           autoPlay
//           loop
//           muted
//         >
//           <source
//             src={video} // Dynamic video source
//             type="video/mp4"
//           />
//           Your browser does not support the video tag.
//         </video>
//         <div className="video-overlay-image">
//           <img
//             src={image} // Dynamic overlay image
//             alt="Overlay"
//           />
//         </div>
//       </div>

//       {/* Product Section */}
//       <h1>Products for Game: {gameCode}</h1>
//       <div className="product-grid">
//         {products.map((product) => (
//           <Card
//             key={product.level_name}
//             title={product.level_title}
//             description={product.description || "No description available"}
//             currentPrice={`₹${product.price || "N/A"}`}
//             oldPrice={`₹${product.old_price || "N/A"}`}
//             rating={product.rating || "N/A"}
//             image={product.photo} // Assuming 'photo' is part of product data
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductPage;



// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { fetchProductsByGameCode } from "../../features/products/productsSlice";
// import Card from "../Card/Card"; // Use your reusable card component
// import decryptURI from "../modifier/decryptURI"; // Import the decryptURI function
// import "./ProductPage.css";
// import vid from "../../assets/videos/mov_bbb.mp4";
// import robo from "../../assets/images/robocar.png";
// import mili from "../../assets/images/baner1.png";
// import defaultImage from "../../assets/images/robocar.png"; // Default image for locked cards

// // Map gameCode to video and overlay image
// const mediaMap = {
//   TBF: {
//     video: vid, // Replace with actual video path
//     image: robo, // Replace with actual overlay image path
//   },
//   TSC: {
//     video: vid,
//     image: mili,
//   },
//   default: {
//     video: vid,
//     image: robo,
//   },
// };

// const ProductPage = () => {
//   const { gameCode } = useParams(); // Extract gameCode from URL
//   const dispatch = useDispatch();
//   const { products, status, error } = useSelector((state) => state.products);

//   const videoRef = useRef(null); // Video reference for play/pause
//   const [isPlaying, setIsPlaying] = useState(true); // Track play/pause state

//   useEffect(() => {
//     dispatch(fetchProductsByGameCode(gameCode)); // Fetch products by gameCode
//   }, [dispatch, gameCode]);

//   const togglePlayPause = () => {
//     if (videoRef.current) {
//       if (isPlaying) {
//         videoRef.current.pause();
//       } else {
//         videoRef.current.play();
//       }
//       setIsPlaying(!isPlaying);
//     }
//   };

//   // Ensure case-insensitive matching for gameCode
//   const { video, image } = mediaMap[gameCode?.toUpperCase()] || mediaMap.default;

//   if (status === "loading") return <p>Loading products...</p>;
//   if (status === "failed") return <p>Error: {error}</p>;

//   return (
//     <div className="product-page">
//       {/* Video Section */}
//       <div className="video-container" onClick={togglePlayPause}>
//         <video
//           ref={videoRef}
//           className="background-video"
//           autoPlay
//           loop
//           muted
//         >
//           <source
//             src={video} // Dynamic video source
//             type="video/mp4"
//           />
//           Your browser does not support the video tag.
//         </video>
//         <div className="video-overlay-image">
//           <img
//             src={image} // Dynamic overlay image
//             alt="Overlay"
//           />
//         </div>
//       </div>

//       {/* Product Section */}
//       <h1>Products for Game: {gameCode}</h1>
//       <div className="product-grid">
//         {products.map((product) => {
//           let decryptedPhoto;
//           try {
//             decryptedPhoto = product.promotion
//               ? decryptURI(product.photo) // Decrypt only if promotion is true
//               : defaultImage; // Use default image if promotion is false
//           } catch (error) {
//             console.error("Failed to decrypt photo URL:", error);
//             decryptedPhoto = defaultImage; // Use default image on failure
//           }

//           // Calculate the current price
//           const currentPrice = Math.round(
//             product.price - (product.price * (product.discount || 0)) / 100
//           );

//           return (
//             <div
//               className={`card-wrapper ${!product.promotion ? "sold-out" : ""}`}
//               key={product.level_name}
//             >
//               <Card
//                 title={product.level_name} // level_name, level_title
//                 description={product.level_title || "No description available"}
//                 currentPrice={
//                   product.promotion
//                     ? `₹${currentPrice}` // Show price if promotion is true
//                     : "Sold Out"
//                 }
//                 oldPrice={product.promotion ? `₹${product.price}` : ""} // Hide original price if locked
//                 rating={product.rating || "N/A"}
//                 image={decryptedPhoto} // Use decrypted or default photo URL
//               />
//               {!product.promotion && (
//                 <div className="sold-out-overlay">
//                   <p>Sold Out</p>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default ProductPage;


import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchProductsByGameCode } from "../../features/products/productsSlice";
import { fetchProductDetails } from "../../features/products/purchaseSlice"; // Import the Redux action for purchase
import Card from "../Card/Card"; // Use your reusable card component
import decryptURI from "../modifier/decryptURI"; // Import the decryptURI function
import "./ProductPage.css";
import vid1 from "../../assets/images/tbf.webp";
import vid2 from "../../assets/images/tsc.webp";
import robo from "../../assets/images/battle_field.png";
import mili from "../../assets/images/smart_city.png";
import defaultImage from "../../assets/images/logo.png"; // Default image for locked cards


// Map gameCode to video and overlay image
const mediaMap = {
  TBF: {
    video: vid1, // Replace with actual video path
    image: robo, // Replace with actual overlay image path
  },
  TSC: {
    video: vid2,
    image: mili,
  },
  default: {
    video: vid1,
    image: robo,
  },
};

const ProductPage = () => {
  const { gameCode } = useParams(); // Extract gameCode from URL
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For navigation
  const { products, status, error } = useSelector((state) => state.products);

  // const videoRef = useRef(null); // Video reference for play/pause
  // const [isPlaying, setIsPlaying] = useState(true); // Track play/pause state

  useEffect(() => {
    dispatch(fetchProductsByGameCode(gameCode)); // Fetch products by gameCode
  }, [dispatch, gameCode]);

  // const togglePlayPause = () => {
  //   if (videoRef.current) {
  //     if (isPlaying) {
  //       videoRef.current.pause();
  //     } else {
  //       videoRef.current.play();
  //     }
  //     setIsPlaying(!isPlaying);
  //   }
  // };

  const handleTryNow = (levelCode) => {
    console.log("afdnsudsj",levelCode)
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');
    navigate(`/tasks/${levelNameWithHyphens}`,{state:{
      levelName: levelCode.level_name, 
      levelCode: levelCode.level_code
  }}); // Navigate to tasks page
  };

  const handleBuyNow = (levelCode) => {
    // Dispatch an action to initiate purchase
    dispatch(fetchProductDetails(levelCode.level_code));
    
    // Replace spaces with hyphens in level_name
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');

    // Navigate to purchase page with modified levelName
    navigate(`/stem-learning-kit/${levelNameWithHyphens}`, {
        state: {
            levelName: levelCode.level_name, 
            levelCode: levelCode.level_code
        }
    });
};
  // Ensure case-insensitive matching for gameCode
  const { video, image } = mediaMap[gameCode?.toUpperCase()] || mediaMap.default;

  if (status === "loading") return <p>Loading products...</p>;
  if (status === "failed") return <p>Error: {error}</p>;

  return (
    <div className="product-page">
      {/* Video Section */}
      <div className="video-container">
        <img
            src={video} 
            alt="stem"
          />
        {/* <div className="video-overlay-image">
          <img
            src={image} // Dynamic overlay image
            alt="Overlay"
          />
        </div> */}
      </div>

      {/* Product Section */}
      <h1>Learn & Play Kits: {gameCode}</h1>
      <div className="product-grid">
        {products.map((product) => {
          let decryptedPhoto;
          try {
            decryptedPhoto = product.promotion
              ? decryptURI(product.photo) // Decrypt only if promotion is true
              : defaultImage; // Use default image if promotion is false
          } catch (error) {
            console.error("Failed to decrypt photo URL:", error);
            decryptedPhoto = defaultImage; // Use default image on failure
          }

          // Calculate the current price
          const currentPrice = Math.round(
            product.price - (product.price * (product.discount || 0)) / 100
          );

          return (
            <div
              className={`card-wrapper ${!product.promotion ? "sold-out" : ""}`}
              key={product.level_name}
            >
              <Card
                title={product.level_name} // level_name, level_title
                description={product.level_title }
                currentPrice={
                  product.promotion
                    ? `₹${currentPrice}` // Show price if promotion is true
                    : "Sold Out"
                }
                oldPrice={product.promotion ? `₹${product.price}` : ""} // Hide original price if locked
                rating={product.rating || "4.8"}
                image={decryptedPhoto} // Use decrypted or default photo URL
                onTryNow={() => handleTryNow(product)} // Pass level_code to handler
                onBuyNow={() => handleBuyNow(product)} // Pass level_code for purchase
              />
              {/* {!product.promotion && (
                <div className="sold-out-overlay">
                  <p>Sold Out</p>
                </div>        
              )} */}
            </div>
          );
        })}
      </div>
      <br />
      
    </div>
  );
};

export default ProductPage;
