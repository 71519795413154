import React from 'react';
import { Box, Typography } from '@mui/material';
import Star from '@mui/icons-material/Star';
import StarHalf from '@mui/icons-material/StarHalf';
import StarOutline from '@mui/icons-material/StarOutline';

const StarRating = ({ rating }) => {
  const stars = [];

  // Generate stars based on the rating
  for (let i = 1; i <= 5; i++) {
    if (rating >= i) {
      stars.push(<Star key={i} sx={{ color: '#FFD700' }} />); // Full star
    } else if (rating >= i - 0.5) {
      stars.push(<StarHalf key={i} sx={{ color: '#FFD700' }} />); // Half star
    } else {
      stars.push(<StarOutline key={i} sx={{ color: '#FFD700' }} />); // Empty star
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
      <Typography sx={{ marginLeft: '8px', fontSize: '18px', color: '#FFD700' }}>
        {rating.toFixed(1)}
      </Typography>
    </Box>
  );
};

export default StarRating;
