// // import React from "react";
// // import decryptURI from "../modifier/decryptURI";

// // const PdfViewer = (datas) => {
// //   const pdfUrl = "/path-to-your-file.pdf"; // Replace with your PDF file's path or URL
// //   console.log(decryptURI(datas.data.tasks[0].media[0].media_path))
// //   return (
// //     <div style={{ width: "100%", height: "100vh" }}>
// //       <iframe
// //         src={decryptURI(datas.data.tasks[0].media[0].media_path)}
// //         width="100%"
// //         height="100%"
// //         style={{ border: "none" }}
// //         title="PDF Viewer"
// //       />
// //     </div>
// //   );
// // };

// // export default PdfViewer;


// import React, { useEffect, useRef } from "react";
// import * as pdfjsLib from "pdfjs-dist";
// import decryptURI from "../modifier/decryptURI";

// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

// const PdfViewer = (datas) => {
//   const canvasRef = useRef(null);
//   const pdfUrl = decryptURI(datas.data.tasks[0].media[0].media_path);

//   useEffect(() => {
//     const renderPDF = async () => {
//       try {
//         const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
//         const page = await pdf.getPage(1); // Render the first page

//         const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale for better resolution
//         const canvas = canvasRef.current;
//         const context = canvas.getContext("2d");

//         canvas.width = viewport.width;
//         canvas.height = viewport.height;

//         const renderContext = {
//           canvasContext: context,
//           viewport: viewport,
//         };

//         await page.render(renderContext);
//       } catch (error) {
//         console.error("Error loading PDF:", error);
//       }
//     };

//     renderPDF();
//   }, [pdfUrl]);

//   return (
//     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
//       <canvas ref={canvasRef} style={{ border: "1px solid #ccc" }}></canvas>
//     </div>
//   );
// };

// export default PdfViewer;


import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import decryptURI from '../modifier/decryptURI';

// Ensure you use a stable version of pdfjs-dist (e.g., 2.10.377)
const PdfViewer = (datas) => {
  const pdfUrl = decryptURI(datas.data.tasks[0].media[0].media_path);

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      {/* Use a correct CDN for the PDF.js worker */}
      <Worker workerUrl="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
  <Viewer fileUrl={pdfUrl} />
</Worker>
    </div>
  );
};

export default PdfViewer;

