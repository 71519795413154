import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch game data from the backend
export const fetchGames = createAsyncThunk("games/fetchGames", async () => {
  const response = await fetch("https://almondai.in/ilpapi/games/"); // Replace with your API endpoint
  if (!response.ok) {
    throw new Error("Failed to fetch games");
  }
  const data = await response.json();
  return data;
});

const gameSlice = createSlice({
  name: "games",
  initialState: {
    games: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.games = action.payload;
      })
      .addCase(fetchGames.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default gameSlice.reducer;
