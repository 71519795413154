// import React from "react";
// import "./Card.css";
// import CardImage from "./CardImage";
// import BuyNowButton from "./BuyNowButton";
// import TryNowButton from "./TryNowButton";
// import Ratings from "./Ratings";
// import Offers from "./Offers";

// const Card = () => {
//   return (
//     <div className="card-container">
//       {/* Image Section */}
//       <div className="image-section">
//         <Offers text="OFFERS" />
//         <CardImage />
//       </div>

//       {/* Content Section */}
//       <div className="card-content">
//         <div className="title-and-rating">
//           <h3>Military Jeep</h3>
//           <Ratings value="4.8" />
//         </div>
//         <p className="description">
//           STEM Learning Kit - Military Jeep for Kids by iLearnPlace | for Boys &
//           Girls Aged 6+ | Boost Creativity & Problem-Solving Skills | 50+
//           Experiments & 12+ STEM Tasks
//         </p>

//         {/* Price Section */}
//         <div className="price-section">
//           <span className="current-price">Rs. 3499/-</span>
//           <span className="old-price">Rs. 3999</span>
//         </div>

//         {/* Buttons */}
//         <div className="card-buttons">
//           <TryNowButton />
//           <BuyNowButton />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;
// import React from "react";
// import "./Card.css";

// const Card = ({ title, description, currentPrice, oldPrice, rating, image }) => {
//   return (
//     <div className="card-container">
//       {/* Image Section */}
//       <div className="image-section">
//         <div className="offers-tag">OFFERS</div>
//         <img src={image} alt={title} className="card-image" />
//       </div>

//       {/* Content Section */}
//       <div className="card-content">
//         <div className="title-and-rating">
//           <h3>{title}</h3>
//           <div className="rating">
//             {rating} <span className="star">★</span>
//           </div>
//         </div>
//         <p className="description">{description}</p>

//         {/* Price Section */}
//         <div className="price-section">
//           <span className="current-price">{currentPrice}</span>
//           <span className="old-price">{oldPrice}</span>
//         </div>

//         {/* Buttons */}
//         <div className="card-buttons">
//           <button className="try-now">Try Now</button>
//           <button className="buy-now">Buy Now</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;


import React from "react";
import "./Card.css";

const Card = ({
  title,
  description,
  currentPrice,
  oldPrice,
  rating,
  image,
  onTryNow,
  onBuyNow,
}) => {
  return (
    <div className="card-container" onClick={onBuyNow} style={{ cursor: "pointer" }}>
      {/* Image Section */}
      <div className="image-section">
        <div className="offers-tag">OFFERS</div>
        <img src={image} alt={title} className="card-image" />
      </div>

      {/* Content Section */}
      <div className="card-content">
        <div className="title-and-rating">
          <h3>{title}</h3>
          <div className="rating">
            {rating} <span className="star">★</span>
          </div>
        </div>
        <p className="description">{description}</p>

        {/* Price Section */}
        <div className="price-section">
          <span className="current-price">{currentPrice}</span>
          <span className="old-price">{oldPrice}</span>
        </div>

        {/* Buttons */}
        <div className="card-buttons">
          <button
            className="try-now"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click event bubbling to card-container
              onTryNow();
            }}
          >
            Try Now
          </button>
          <button
            className="buy-now"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click event bubbling to card-container
              onBuyNow();
            }}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;


