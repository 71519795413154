import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, Paper, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";

const MediaUploadPage = ({ data }) => {
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [uploadedVideos, setUploadedVideos] = useState([]); // State to hold uploaded videos

  const taskId = data.tasks[0].task[0].id; // Extract taskId from data object
  const token = localStorage.getItem("token");

  const validVideoFormats = ["video/mp4", "video/x-matroska", "video/x-msvideo", "video/quicktime"]; // mp4, mkv, avi, mov

  const onDrop = (acceptedFiles) => {
    const videoFile = acceptedFiles[0];
    if (!videoFile || !validVideoFormats.includes(videoFile.type)) {
      setError("Only video files in mp4, mkv, avi, or mov formats are allowed.");
      setFile(null);
      return;
    }
    setError("");
    setFile(videoFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: validVideoFormats.join(","),
    maxSize: 200 * 1024 * 1024, // 200MB
  });

  const handleUpload = async () => {
    if (!file || !mediaTitle || !mediaDescription) {
      setError("Please fill in all fields and select a valid file.");
      return;
    }
    setError("");

    const formData = new FormData();
    formData.append("media_files", file);
    formData.append("media_title", mediaTitle);
    formData.append("media_description", mediaDescription);
    formData.append("task_id", taskId);

    try {
      const response = await fetch("https://almondai.in/ilpapi/student/media", {
        method: "POST",
        headers: {
          Authorization: token, // Attach JWT token
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Upload failed");
      }

      setUploadSuccess("Upload successful!");
      clearFields(); // Clear fields upon success

      // Hide success message after 3 seconds
      setTimeout(() => {
        setUploadSuccess(null);
      }, 3000);

      // After uploading, fetch the uploaded videos
      fetchUploadedVideos();
    } catch (err) {
      setError(err.message || "An error occurred during upload.");
    }
  };

  // Fetch already uploaded videos from the backend
  const fetchUploadedVideos = async () => {
    try {
      const response = await fetch(`https://almondai.in/ilpapi/student/media/${taskId}/`, {
        method: "GET",
        headers: {
          Authorization: token, // Attach JWT token
        },
      });

      const data = await response.json();
      if (data.success) {
        setUploadedVideos(data.data.videos); // Update state with fetched videos
      } else {
        setError(data.message || "Failed to fetch videos");
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching videos.");
    }
  };

  useEffect(() => {
    fetchUploadedVideos(); // Fetch videos on initial load
  }, []);

  const clearFields = () => {
    setMediaTitle("");
    setMediaDescription("");
    setFile(null);
    setError("");
  };

  return (
    <Box sx={{ p: 4, maxWidth: 1200, mx: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Upload Video
      </Typography>

      {/* Media Title */}
      <TextField
        label="Media Title"
        fullWidth
        variant="outlined"
        value={mediaTitle}
        onChange={(e) => setMediaTitle(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* Media Description */}
      <TextField
        label="Media Description"
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={mediaDescription}
        onChange={(e) => setMediaDescription(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* File Uploader */}
      <Paper
        variant="outlined"
        {...getRootProps()}
        sx={{
          p: 3,
          textAlign: "center",
          backgroundColor: "#f9f9f9",
          borderRadius: 1,
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1">
          {file ? `Selected file: ${file.name}` : "Drag and drop a video file here or click to browse"}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Limit 200MB per file • Only mp4, mkv, avi, mov formats allowed
        </Typography>
      </Paper>

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      {uploadSuccess && (
        <Typography color="primary" variant="body2" sx={{ mt: 1 }}>
          {uploadSuccess}
        </Typography>
      )}

      {/* Upload Button */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3 }}
        onClick={handleUpload}
        disabled={!file || !mediaTitle || !mediaDescription}
      >
        Upload
      </Button>

      {/* Uploaded Videos Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Already Uploaded Videos
        </Typography>

        {/* Grid to show videos like YouTube */}
        <Grid container spacing={3}>
          {uploadedVideos.length > 0 ? (
            uploadedVideos.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: "100%" }}>
                  {/* Video Thumbnail */}
                  <CardMedia
                    component="video"
                    controls
                    src={video.s3_urls}
                    sx={{
                      height: 200,
                      objectFit: "cover",
                    }}
                  />

                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {video.media_title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {video.media_description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            
            <Typography>No videos uploaded yet.</Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default MediaUploadPage;
