// import React from "react";
// import { Box, Typography } from "@mui/material";

// const ProductDescriptionPage = (descdata) => {
//   console.log("data",descdata?.desc)
//   return (
//     <Box textAlign="center" p={4}>
//       {/* Heading Section */}
//       <Box textAlign="center" mb={4}>
//         <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2 }}>
//           Descriptions
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: { xs: "90%", md: "60%" },
//             margin: "0 auto",
//           }}
//         />
//       </Box>

//       {/* Main Description */}
//       <Box maxWidth="800px" mx="auto" mb={4}>
//         <Typography variant="body1" align="left" sx={{ mb: 2 }}>
//           {descdata.desc}
//         </Typography>

//         <Typography variant="body1" align="left">
//         {descdata.desc}
//           <Typography component="span" sx={{ fontWeight: "bold" }}>
//             safe, child-friendly materials,
//           </Typography>{" "}
//           this kit offers a stress-free experience for parents and an exciting challenge
//           for kids. Whether it's a birthday, holiday, or special occasion, this kit is
//           the gift that keeps on giving—combining fun and education in a way that grows
//           with your child!
//         </Typography>
//       </Box>

//       {/* Features Section */}
//       <Box maxWidth="800px" mx="auto">
//         {/* Feature 1 */}
//         <Box display="flex" mb={2}>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", flexBasis: "35%" }}
//             align="left"
//           >
//             🔍 FUN & EDUCATIONAL
//           </Typography>
//           <Typography
//             variant="body1"
//             color="textSecondary"
//             sx={{ flexBasis: "65%" }}
//             align="left"
//           >
//             Unlock the magic of hands-on learning with the STEM Learning Kit - Military
//             Jeep by iLearnPlace. Designed for kids aged 6 and up, this kit combines
//             robotics, science, and engineering in one exciting package! With over{" "}
//             <Typography component="span" sx={{ fontWeight: "bold" }}>
//               50 experiments and 11+ STEM tasks,
//             </Typography>{" "}
//             your child will explore creative solutions, experiment with problem-solving,
//             and develop critical thinking—all while having fun!
//           </Typography>
//         </Box>

//         {/* Feature 2 */}
//         <Box display="flex" mb={2}>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", flexBasis: "35%" }}
//             align="left"
//           >
//             💡 BOOSTS CREATIVITY & PROBLEM-SOLVING
//           </Typography>
//           <Typography
//             variant="body1"
//             color="textSecondary"
//             sx={{ flexBasis: "65%" }}
//             align="left"
//           >
//             Engage your child’s imagination through a wide variety of experiments. Whether
//             constructing the jeep or solving engineering challenges, the tasks in this
//             kit inspire curiosity and encourage outside-the-box thinking.
//           </Typography>
//         </Box>

//         {/* Feature 3 */}
//         <Box display="flex" mb={2}>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", flexBasis: "35%" }}
//             align="left"
//           >
//             🔰 SAFE & CHILD-FRIENDLY
//           </Typography>
//           <Typography
//             variant="body1"
//             color="textSecondary"
//             sx={{ flexBasis: "65%" }}
//             align="left"
//           >
//             Your child’s safety is our top priority. That’s why all materials are made
//             from{" "}
//             <Typography component="span" sx={{ fontWeight: "bold" }}>
//               non-toxic,
//             </Typography>{" "}
//             durable components, ensuring safe play. Easy-to-follow instructions allow
//             kids to complete each experiment independently, building confidence along the
//             way.
//           </Typography>
//         </Box>

//         {/* Feature 4 */}
//         <Box display="flex" mb={2}>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", flexBasis: "35%" }}
//             align="left"
//           >
//             🎁 THE PERFECT GIFT
//           </Typography>
//           <Typography
//             variant="body1"
//             color="textSecondary"
//             sx={{ flexBasis: "65%" }}
//             align="left"
//           >
//             Looking for a gift that’s both fun and educational? The iLearnPlace Military
//             Jeep Kit is perfect for birthdays, holidays, or any special occasion. It's
//             more than just a toy—it’s a stepping stone to STEM careers, wrapped in hours
//             of exciting play.
//           </Typography>
//         </Box>

//         {/* Feature 5 */}
//         <Box display="flex" mb={2}>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", flexBasis: "35%" }}
//             align="left"
//           >
//             🎮 GAMIFIED LEARNING EXPERIENCE
//           </Typography>
//           <Typography
//             variant="body1"
//             color="textSecondary"
//             sx={{ flexBasis: "65%" }}
//             align="left"
//           >
//             To keep kids engaged, we've gamified the learning process! With multiple
//             gameplay options and rewarding challenges, the kit turns every task into a
//             fun adventure. Your child will stay motivated and excited to learn more,
//             making it a perfect alternative to other DIY toys.
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default ProductDescriptionPage;


// import React from 'react';
// import { Box, Typography, Grid } from '@mui/material';

// // The component accepts descdata as props
// const ProductDescriptionPage = ({ descdata }) => {
//   return (
//     <Box textAlign="center" p={4}>
//       {/* Heading Section */}
//       <Box textAlign="center" mb={4}>
//         <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2 }}>
//           Descriptions
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: { xs: "90%", md: "60%" },
//             margin: "0 auto",
//           }}
//         />
//       </Box>

//       {/* Main Description */}
//       <Box width="100%" mx="auto" mb={4}>
//         <Typography variant="body1" align="left" sx={{ mb: 2 }}>
//           {descdata?.desc || "Default description text"}
//         </Typography>
//       </Box>

//       {/* Features Section */}
//       <Box maxWidth="800px" mx="auto">
//         {/* Dynamically map over the 'key' array */}
//         {descdata?.key?.map((feature, index) => (
//           <Box display="flex" mb={2} key={index}>
//             <Typography variant="h6" sx={{ fontWeight: "bold", flexBasis: "35%" }} align="left">
//               {feature.title}
//             </Typography>
//             <Typography variant="body1" color="textSecondary" sx={{ flexBasis: "65%" }} align="left">
//               {feature.doc}
//             </Typography>
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ProductDescriptionPage;




import React, { useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';

// The component accepts descdata as props
const ProductDescriptionPage = ({ descdata }) => {
  const [showAll, setShowAll] = useState(false);

  // Handle the display of features
  const featuresToDisplay = showAll ? descdata?.key : descdata?.key?.slice(0, 4);

  return (
    <Box textAlign="center" p={4}>
      {/* Heading Section */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2 }}>
          Descriptions
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #f4f6f7",
            width: { xs: "90%", md: "60%" },
            margin: "0 auto",
          }}
        />
      </Box>

      {/* Main Description */}
      <Box width="100%" mx="auto" mb={4}>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {descdata?.desc || "Default description text"}
        </Typography>
      </Box>

      {/* Features Section */}
      <Box maxWidth="800px" mx="auto">
        {/* Dynamically map over the 'key' array */}
        {featuresToDisplay?.map((feature, index) => (
          <Box display="flex" mb={2} key={index}>
            <Typography variant="h6" sx={{ fontWeight: "bold", flexBasis: "35%" }} align="left">
              {feature.title}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ flexBasis: "65%" }} align="left">
              {feature.doc}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Read More Button */}
      {descdata?.key?.length > 4 && (
        <Button variant="text" onClick={() => setShowAll(!showAll)} sx={{ mt: 2 }}>
          {showAll ? 'Show Less' : 'Read More'}
        </Button>
      )}
    </Box>
  );
};

export default ProductDescriptionPage;
