import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

// Root styling
const Root = styled("div")(() => ({
  width: "100%",
  maxWidth: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const TermsCondition = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      <Typography
        variant="h1"
        gutterBottom
        style={{
          fontSize: "2.5rem",
          textAlign: "center",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
        }}
      >
        TERMS & CONDITIONS
      </Typography>
      <Container maxWidth="md">
        <Typography variant="body1" gutterBottom>
          Welcome to Almondai Technologies Private Limited (“Ilearnplace”). If
          you continue to browse and use this website, you agree to comply with
          and be bound by the following terms and conditions of use, which
          together with our privacy policy govern Ilearnplace's relationship
          with you in relation to this website.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The term "Ilearnplace" refers to the owner of the website and the
          term "you" refers to the user or viewer of our website.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The term "Services" refers to all products available on the website
          for viewing and purchase by the user and the online portal hosted by
          Almondai Group - Ilearnplace to post pictures and videos of projects
          created under the supervision of Ilearnplace as well as User Project
          Uploads (defined in Para 12 of these Terms and Conditions).
        </Typography>

        <Typography variant="body1" gutterBottom>
          The term "Products" refers to the robotic kits, parts, and licenses
          available for sale on the website of Ilearnplace.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The Products sold are for personal use only. Commercial usage of the
          Products is not allowed without written consent from Ilearnplace. You
          can write to contact@Ilearnplace.com for requests to use the product
          for commercial purposes.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>The use of this website is subject to the following terms:</b>
        </Typography>

        <Typography variant="body1" gutterBottom>
          1. The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Neither we nor any third parties provide any warranty or guarantee
          as to the accuracy, timeliness, performance, completeness, or
          suitability of the information and materials (including product
          components like batteries, etc.) found or offered on this website for
          any particular purpose.
        </Typography>

        <Typography variant="body1" gutterBottom>
          3. Unauthorized use of this website may give rise to a claim for
          damages and/or be a criminal offense.
        </Typography>

        <Typography variant="body1" gutterBottom>
          4. From time to time, this website may include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </Typography>

        <Typography variant="body1" gutterBottom>
          By using Ilearnplace's website and services, you agree to be governed
          by and construed under the laws of the Republic of India and to the
          jurisdiction of the courts of Chennai, India.
        </Typography>
      </Container>
    </Root>
  );
};

export default TermsCondition;
