// import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./LoginButton.css";

// const LoginButton = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleLoginClick = () => {
//     // Save the current route in localStorage
//     localStorage.setItem("prevRoute", location.pathname);
//     navigate("/login"); // Redirect to login page
//   };

//   const handleProfileClick = () => {
//     navigate("/profile"); // Redirect to profile page
//   };

//   const isLoggedIn = !!localStorage.getItem("token"); // Check if user is logged in by token presence

//   return (
//     <button
//       className="styled-login-button"
//       onClick={isLoggedIn ? handleProfileClick : handleLoginClick}
//     >
//       {isLoggedIn ? "Profile" : "Login"}
//     </button>
//   );
// };

// export default LoginButton;


// import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./LoginButton.css";
// import avatarImage from "../../assets/images/avatar.png"; // Replace with the correct path to your image

// const LoginButton = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleLoginClick = () => {
//     // Save the current route in localStorage
//     localStorage.setItem("prevRoute", location.pathname);
//     navigate("/login"); // Redirect to login page
//   };

//   const handleProfileClick = () => {
//     navigate("/profile"); // Redirect to profile page
//   };

//   const isLoggedIn = !!localStorage.getItem("token"); // Check if user is logged in by token presence

//   return (
//     <div className="login-button-container">
//       {isLoggedIn ? (
//         <img
//           src={avatarImage}
//           alt="Profile Avatar"
//           className="avatar-button"
//           onClick={handleProfileClick}
//         />
//       ) : (
//         <button className="styled-login-button" onClick={handleLoginClick}>
//           Login
//         </button>
//       )}
//     </div>
//   );
// };

// export default LoginButton;


import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoginButton.css";
import avatarImage from "../../assets/images/avatar.png"; // Replace with the correct path to your image

const LoginButton = ({ onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginClick = () => {
    if (onClick) onClick(); // Call parent function (e.g., close menu) if provided
    localStorage.setItem("prevRoute", location.pathname); // Save current route
    navigate("/login"); // Redirect to login page
  };

  const handleProfileClick = () => {
    if (onClick) onClick(); // Call parent function (e.g., close menu) if provided
    navigate("/profile"); // Redirect to profile page
  };

  const isLoggedIn = !!localStorage.getItem("token"); // Check if user is logged in

  return (
    <div className="login-button-container">
      {isLoggedIn ? (
        <img
          src={avatarImage}
          alt="Profile Avatar"
          className="avatar-button"
          onClick={handleProfileClick}
        />
      ) : (
        <button className="styled-login-button" onClick={handleLoginClick}>
          Login
        </button>
      )}
    </div>
  );
};

export default LoginButton;
