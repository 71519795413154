// import React, { useEffect, useState } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ videoData }) => {
//   const [videos, setVideos] = useState([]);
//   const isMobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     // If videoData is passed, use it; otherwise, use default videos
//     if (videoData && videoData.length > 0) {
//       setVideos(videoData);
//     } else {
//       setVideos(defaultVideos);
//     }
//   }, [videoData]);

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {videos.map((video) => (
//           <Grid item xs={12} sm={6} md={4} key={video.id}>
//             <Box sx={{ textAlign: "center" }}>
//               <iframe
//                 width="100%"
//                 height={isMobile ? "200" : "400"}
//                 src={video.url}
//                 title={video.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//               <Typography variant="h6" sx={{ marginTop: "10px" }}>
//                 {video.title}
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;






// import React, { useEffect, useState } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ videoData }) => {
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     // If videoData is passed, use it; otherwise, use default videos
//     setVideos(videoData && videoData.length > 0 ? videoData : defaultVideos);
//   }, [videoData]);

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL
//     const videoId = videoUrl.split("/embed/")[1];
//     return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Get YouTube thumbnail image
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     maxWidth: "400px",
//                     textAlign: "left",
//                     lineHeight: 1.5,
//                   }}
//                 >
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;



// import React, { useEffect, useState, useRef } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ videoData }) => {
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const videoPlayerRef = useRef(null); // Ref to track the video player

//   useEffect(() => {
//     // If videoData is passed, use it; otherwise, use default videos
//     setVideos(videoData && videoData.length > 0 ? videoData : defaultVideos);
//   }, [videoData]);

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click

//     // Scroll to the video player in mobile view
//     if (isMobile && videoPlayerRef.current) {
//       videoPlayerRef.current.scrollIntoView({ behavior: "smooth", block:"start" });
//     }
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL
//     const videoId = videoUrl.split("/embed/")[1];
//     return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Get YouTube thumbnail image
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     maxWidth: "400px",
//                     textAlign: "left",
//                     lineHeight: 1.5,
//                   }}
//                 >
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;



import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// Default videos to show if no data is fetched
const defaultVideos = [
  { id: 1, title: "Unbox & Build the Ultimate ILP STEM Military Jeep – Begin Your Gamified Adventure!", url: "https://www.youtube.com/embed/FbQDlI0P5_U?si=pq--SwBo7L2fpCMP" },
  { id: 2, title: "Takshak Battle Field Level-1 Kit Assembly | Robo Car Kit Assembly", url: "https://www.youtube.com/embed/FOrE6sohnDo?si=nZIvFcjcxpWJeLdr" },
  { id: 3, title: "Shining house assembling", url: "https://www.youtube.com/embed/KHi6X_Srplc?si=UKBDyGCx4xwgYsPR" },
  { id: 3, title: "Totoka Smart City Level-2 | Task-1 | Water Tank Tower Assembly", url: "https://www.youtube.com/embed/RCvy4CYqJ3I?si=XeHBP3XTFTl5BOS2" },
  { id: 3, title: "Smart Railway Crossing | Advance Smart City Classical Train Set Review", url: "https://www.youtube.com/embed/sOOysD0sz9E?si=5z6J05uKFlMQcsgU" },
  { id: 3, title: "STEM Learning With The Advance Military Jeep For Kids || STEM Robot Kit", url: "https://www.youtube.com/embed/FJJQt-V0cD8?si=sfUI0pWm1CF_50Rj" },
];

const VideoPage = ({ videoData }) => {
  const [videos, setVideos] = useState([]);
  const [mainVideo, setMainVideo] = useState(null); // Track the current main video
  const isMobile = useMediaQuery("(max-width:600px)");
  const videoPlayerRef = useRef(null); // Ref to track the video player
  const navbarHeight = 70; // Set the approximate height of your navbar

  useEffect(() => {
    // If videoData is passed, use it; otherwise, use default videos
    setVideos(videoData && videoData.length > 0 ? videoData : defaultVideos);
  }, [videoData]);

  // Set the first video as the default main video
  useEffect(() => {
    if (!mainVideo && videos.length > 0) {
      setMainVideo(videos[0]);
    }
  }, [videos, mainVideo]);

  const handleVideoClick = (video) => {
    setMainVideo(video); // Update the main video on click

    // Scroll to the video player with offset for the navbar
    if (videoPlayerRef.current) {
      const elementPosition = videoPlayerRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - navbarHeight; // Adjust by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const getThumbnailUrl = (videoUrl) => {
    // Extract video ID from the YouTube URL, ensuring to handle query strings or additional parameters
    const videoIdMatch = videoUrl.match(/\/embed\/([^?]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
  
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Construct YouTube thumbnail URL
    } else {
      console.error("Invalid YouTube URL provided.");
      return null; // Return null if video ID extraction fails
    }
  };
  
  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
      Interactive Video Series
      </Typography>

      <Grid container spacing={3}>
        {/* Main Video on the left side */}
        <Grid item xs={12} md={8}>
          <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
            {mainVideo && (
              <iframe
                width="100%"
                height={isMobile ? "300" : "450"}
                src={mainVideo.url}
                title={mainVideo.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              {mainVideo?.title}
            </Typography>
          </Box>
        </Grid>

        {/* Thumbnail Videos on the right side */}
        <Grid item xs={12} md={4}>
          <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
            {videos.map((video) => (
              <Box
                key={video.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleVideoClick(video)}
              >
                <Box
                  component="img"
                  src={getThumbnailUrl(video.url)}
                  alt={video.title}
                  sx={{
                    width: isMobile ? "150px" : "120px",
                    height: "auto",
                    borderRadius: "4px",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: "400px",
                    textAlign: "left",
                    lineHeight: 1.5,
                  }}
                >
                  {video.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoPage;
