// import React from "react";
// import { Box, Typography, Grid } from "@mui/material";
// import decryptURI from "../modifier/decryptURI";

// // Mock feature data


// const hw = "https://via.placeholder.com/1200x600?text=In+Box+Image";

// const FeaturesPage = ({ Featuresimage }) => {
//   return (
//     <Box sx={{ padding: "20px" }}>
//       {/* Heading */}
//       <Box textAlign="center" mb={4}>
//         <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 1 }}>
//           Features
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: { xs: "90%", md: "60%" },
//             margin: "0 auto",
//           }}
//         />
//       </Box>

//       {/* Feature Grid */}
//       <Grid container spacing={2} justifyContent="center">
//         {Featuresimage.feature.map((media, index) => (
//           <Grid item xs={12} sm={6} md={3} key={index}>
//             <Box
//               sx={{
//                 textAlign: "center",
//                 padding: 2,
//                 border: "2px solid #000",
//                 borderRadius: "8px",
//                 maxWidth: "90%",
//                 margin: "0 auto",
//               }}
//             >
//               <img
//                 src={decryptURI(media.media_url)}
//                 alt={`Feature ${index + 1}`}
//                 style={{
//                   width: "100%",
//                   height: "auto",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//                 }}
//               />
//               <Typography
//                 variant="body1"
//                 sx={{ mt: 2, fontWeight: "bold", textTransform: "capitalize" }}
//               >
//                 {media.media_name}
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>

//       {/* In Box Section */}
//       <Box textAlign="center" mt={4}>
//         <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 1 }}>
//           In Box
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: { xs: "90%", md: "60%" },
//             margin: "0 auto",
//           }}
//         />
//         <Grid container justifyContent="center">
//           {/* Single Image */}
//           <Grid item xs={12}>
//           {Featuresimage.component.map((media, index) => (
//   <Box key={index} sx={{ textAlign: "center", padding: 2 }}>
//     <img
//       src={decryptURI(media.media_url)} // Use the URL passed from the featuresImages prop
//       alt={media.alt || "Feature Image"} // Use the alt text passed from the prop, or default if not provided
//       style={{ width: "100%", height: "auto", objectFit: "contain" }} // Ensures the images fit properly
//     />
//   </Box>
// ))}
//           </Grid>
//         </Grid>
//       </Box>

//       {/* Amazing Military Jeep Section */}
//       <Box p={2}>
//         {/* Centered Title with Underline */}
//         <Box textAlign="center" mt={4}>
//           <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 1 }}>
//             Amazing Product Video
//           </Typography>
//           <Box
//             sx={{
//               borderBottom: "2px solid #f4f6f7",
//               width: { xs: "90%", md: "60%" },
//               margin: "0 auto",
//             }}
//           />
//         </Box>

//         {/* Full-Width Video Player */}
//         <Box
//           mt={4}
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//           }}
//           >
//           {Featuresimage?.galary?.map((media, index) => (
//           media.media_type === "video" && ( // Only render if media_type is "video"
//             // <Box key={index} sx={{ textAlign: "center", padding: 2 }}>
//               <iframe
//                 width="100%"
//                 height="450"
//                 src={decryptURI(media.media_url)} // Correctly access media_url here
//                 title={`Video ${index + 1}`}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                 referrerPolicy="strict-origin-when-cross-origin"
//                 allowFullScreen
//               ></iframe>
//             // </Box>
//           )
//         ))}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default FeaturesPage;





import React, { useState } from "react";
import { Box, Typography, Grid, Modal } from "@mui/material";
import decryptURI from "../modifier/decryptURI";

const FeaturesPage = ({ Featuresimage }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      {/* In Box Section */}
      <Box textAlign="center" mt={4}>
        <Typography variant="h4" component="h3" sx={{ fontWeight: "bold", mb: 1 }}>
          Hardware List
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #f4f6f7",
            width: { xs: "90%", md: "60%" },
            margin: "0 auto",
          }}
        />
        <Grid
          container
          justifyContent="center"
          spacing={2}
          sx={{
            flexDirection: { xs: "column", md: "row" }, // Vertical on mobile, horizontal on desktop
          }}
        >
          {Featuresimage.component.map((media, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  textAlign: "center",
                  padding: 2,
                  cursor: "pointer", // Pointer cursor for better UX
                }}
                onClick={() => handleOpenModal(decryptURI(media.media_url))}
              >
                <img
                  src={decryptURI(media.media_url)}
                  alt={media.alt || "Feature Image"}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for aesthetics
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Features Section */}
      <Box sx={{ padding: "20px" }}>
        {/* Heading */}
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" component="h3" sx={{ fontWeight: "bold", mb: 1 }}>
            Features
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #f4f6f7",
              width: { xs: "90%", md: "60%" },
              margin: "0 auto",
            }}
          />
        </Box>

        {/* Feature Grid */}
        <Grid container spacing={2} justifyContent="center">
          {Featuresimage.feature.map((media, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  textAlign: "center",
                  padding: 2,
                  border: "2px solid #000",
                  borderRadius: "8px",
                  maxWidth: "90%",
                  margin: "0 auto",
                }}
              >
                <img
                  src={decryptURI(media.media_url)}
                  alt={`Feature ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ mt: 2, fontWeight: "bold", textTransform: "capitalize" }}
                >
                  {media.media_name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Amazing Military Jeep Section */}
      <Box p={2}>
        {/* Centered Title with Underline */}
        <Box textAlign="center" mt={4}>
          <Typography variant="h4" component="h3" sx={{ fontWeight: "bold", mb: 1 }}>
            Amazing Product Video
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #f4f6f7",
              width: { xs: "90%", md: "60%" },
              margin: "0 auto",
            }}
          />
        </Box>

        {/* Full-Width Video Player */}
        <Box
          mt={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {Featuresimage?.galary?.map((media, index) => (
            media.media_type === "video" && (
              <iframe
                key={index}
                width="100%"
                height="450"
                src={decryptURI(media.media_url)}
                title={`Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )
          ))}
        </Box>
      </Box>

      {/* Modal for larger view */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "70%" },
            maxHeight: "90vh", // Set maximum height to 90% of the viewport height
            overflowY: "auto", // Enable scrolling for content larger than max height
            backgroundColor: "#fff",
            boxShadow: 24,
            p: 2,
            outline: "none",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected Feature"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default FeaturesPage;
