import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

// Root styling
const Root = styled("div")(() => ({
  width: "100%",
  maxWidth: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ShippingDeliveryPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      <Typography
        variant="h1"
        gutterBottom
        style={{
          fontSize: "2.5rem",
          textAlign: "center",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
        }}
      >
        SHIPPING & DELIVERY POLICY
      </Typography>
      <Container maxWidth="md">
        <Typography variant="body1" gutterBottom>
          Welcome to Almondai Technologies Private Limited (“Ilearnplace”). If
          you continue to browse and use this website, you are agreeing to
          comply with and be bound by the following terms and conditions of
          use, which together with our privacy policy govern Ilearnplace's
          relationship with you in relation to this website.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Minimum delivery timeline:</b> 3 working days <br />
          <b>Maximum delivery timeline:</b> 15 working days
        </Typography>
        <Typography variant="body1" gutterBottom>
          For domestic buyers, orders are shipped through BlueDart, DTDC, or
          speed post only. Orders will be shipped through DHL, FEDEX, or other
          reliable shippers. Orders will be shipped within <b>3 working days</b>
          , if stocks are available or as per the delivery date agreed at the
          time of order confirmation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Almondai Group - Ilearnplace is not liable for any delay due to custom
          processing, weather conditions, or any other reason in delivery by
          the courier company/postal authorities and only guarantees to hand
          over the consignment to the courier company or postal authorities
          within <b>3 working days</b> from the date of the order and payment or
          as per the delivery date agreed at the time of order confirmation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Delivery of all orders will be to the registered address of the buyer
          as per the information provided at the time of placing the order.
          Almondai Group - Ilearnplace is in no way responsible for any damage
          to the order while in transit to the buyer.
        </Typography>
        <Typography variant="body1" gutterBottom>
          As per Government of India regulations, batteries cannot be shipped
          by air. All the products containing batteries will only be
          transported by land.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Delivery of our services will be confirmed on your mail ID as
          specified during registration. For any issues in utilizing our
          services, you may contact our helpdesk at 809 441 4990 / +91 141 400
          4597 / info@ilearnplace.com.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>IMPORTANT:</b> Generally, our web shop predicts accurate shipping
          charges, but in case of any problems or errors, we may re-calculate
          shipping charges and will only dispatch such orders after acceptance
          from you. If you do not accept the shipping charges, you will get a
          full refund.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Almondai Group - Ilearnplace is not responsible for any extra charges
          once the original package has been shipped. If the customer refuses
          to pay these extra charges, the return shipping and any additional
          fees will be deducted from the cost of the order, and any remaining
          funds will be refunded to the customer.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>SHIPPING AND DELIVERY TO OVERSEAS CUSTOMERS:</b> The kits and
          products shipped to overseas customers (i.e., customers based outside
          the territory of the Republic of India) shall not include or contain
          batteries. Overseas customers can refer to our online portal for
          alternate specifications for operating the kits and products.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Almondai Group - Ilearnplace is not liable for any delay due to custom
          processing, weather conditions, or any other reason in delivery by
          the courier company/postal authorities and only guarantees to hand
          over the consignment to the courier company or postal authorities
          within <b>3 working days</b> from the date of the order and payment or
          as per the delivery date agreed at the time of order confirmation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Any product purchased from our stores may attract custom charges as
          per the rules of your country, and Almondai Group - Ilearnplace is not
          liable for such payments or refunds due to the customer's refusal to
          accept the parcel.
        </Typography>
      </Container>
    </Root>
  );
};

export default ShippingDeliveryPolicy;
