// import { createSlice } from "@reduxjs/toolkit";

// const purchaseSlice = createSlice({
//   name: "purchase",
//   initialState: {
//     purchaseData: {},
//     status: "idle",
//     error: null,
//   },
//   reducers: {
//     initiatePurchase: (state, action) => {
//       state.purchaseData = { levelCode: action.payload };
//     },
//   },
// });

// export const { initiatePurchase } = purchaseSlice.actions;

// export default purchaseSlice.reducer;



import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Fetch product details by levelCode
export const fetchProductDetails = createAsyncThunk(
  "productDetails/fetchProductDetails",
  async (levelCode) => {
    const response = await fetch(
      `https://almondai.in/ilpapi/productinfo/${levelCode}/`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch product details");
    }
    return response.json();
  }
);


const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState: {
    productDetails: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productDetails = action.payload;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default productDetailsSlice.reducer;
