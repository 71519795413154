import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

// Root styling
const Root = styled("div")(() => ({
  width: "100%",
  maxWidth: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ReturnCancellationPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      {/* Centered Heading */}
      <Typography
        variant="h1"
        gutterBottom
        style={{
          fontSize: "2.5rem",
          textAlign: "center",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
        }}
      >
        RETURN & CANCELLATION POLICY
      </Typography>
      <Container maxWidth="md">
        {/* Content */}
        <Typography variant="body1" gutterBottom>
          Ilearnplace believes in helping its customers as far as possible and
          has therefore a liberal return and cancellation policy. Under this
          policy:
        </Typography>
        <Typography variant="body1" gutterBottom>
          If there are any damages or missing parts when you receive the order
          from us, we give 1 week for customers to report to us, and we will
          replace the damaged products or send you the missing parts within 10
          days. However, the case will not be accepted if the products were
          damaged by inappropriate use or carelessness.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To the product need to be exchanged, firstly, please send the photos
          of damaged products to us. We will estimate the damages and decide
          the best way to exchange or return the product. We accept no
          responsibility for improper installation of our products. Electrical
          polarity must be properly observed in hooking up electrical
          components.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We also accept no responsibility for damages caused during the
          shipping or transit of the goods.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In case you feel that the product received is not as shown on the
          site or as per your expectations, you must bring it to the notice of
          our customer service within 24 hours of receiving the product. The
          Customer Service Team, after looking into your complaint, will take
          an appropriate decision.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Cancellations and refunds will be considered only if the request is
          made before we have initiated the process of shipping them. Once the
          product shipping has been initiated, cancellation and refund are not
          available.
        </Typography>
        <Typography variant="body1" gutterBottom>
          At all times, it shall be the sole discretion of Ilearnplace to
          determine whether the product is deemed to be returned and/or
          replaced.
        </Typography>
      </Container>
    </Root>
  );
};

export default ReturnCancellationPolicy;
