// import React from "react";
// import "./Contact.css";

// const ContactUs = () => {
//   return (
//     <section className="contact" id="contact">
//       {/* Heading */}
//       <h2 className="contact-heading">
//         Contact <span className="highlight">Us</span>
//       </h2>

//       {/* Contact Container */}
//       <div className="contact-card">
//         {/* Left Card: Contact Information */}
//         <div className="contact-info-card">
//           <h3>Contact Information</h3>
//           <p>Say something to start a live chat!</p>
//           <ul>
//             <li>
//               <i className="fas fa-phone-alt contact-icon"></i>
//               <a href="tel:+10123456789" className="contact-link">
//                 +91 8094414990
//               </a>
//             </li>
//             <li>
//               <i className="fas fa-envelope contact-icon"></i>
//               <a href="mailto:info@ilearnplace.com" className="contact-link">
//                 info@ilearnplace.com
//               </a>
//             </li>
//             <li>
//               <i className="fas fa-map-marker-alt contact-icon"></i>
//               <a
//                 href="https://www.google.com/maps/place/13%C2%B002'02.1%22N+77%C2%B037'56.5%22E/@13.0339027,77.6297792,17z/data=!3m1!4b1!4m4!3m3!8m2!3d13.0339027!4d77.6323541?hl=en&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="contact-link"
//               >
//                 319/A, 21, Maruti Nagar Hennur Village 5th block Near Good Year English School Bangolre Karnatak -560043
//               </a>
//             </li>
//           </ul>
//           <div className="decorative-circles">
//             <div className="circle blue-circle"></div>
//             <div className="circle light-blue-circle"></div>
//             <div className="circle blue-circle"></div>
//           </div>
//         </div>

//         {/* Right Card: Contact Form */}
//         <div className="contact-form-card">
//           <div className="form-row">
//             <input type="text" placeholder="First Name" />
//             <input type="text" placeholder="Last Name" />
//           </div>
//           <div className="form-row">
//             <input type="email" placeholder="Email" />
//             <input type="text" placeholder="Phone Number" />
//           </div>
//           <textarea placeholder="Write your message..."></textarea>
//           <button className="send-message-button">Send Message</button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ContactUs;



import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendContactUsData } from "../../features/products/contactusSlice"; // Import the action
import "./Contact.css";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.contactus);

  // State to handle form inputs and validation errors
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Clear error when user starts typing
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    // Check if fields are empty
    if (!formData.firstName) errors.firstName = "First Name is required";
    if (!formData.lastName) errors.lastName = "Last Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.phone) errors.phone = "Phone Number is required";
    if (!formData.message) errors.message = "Message is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If there are no errors, return true
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields before submitting
    if (!validateForm()) {
      return; // Don't submit if there are validation errors
    }

    // Dispatch the action to send the contact form data
    dispatch(sendContactUsData(formData));
  };

  return (
    <section className="contact" id="contact">
      {/* Heading */}
      <h2 className="contact-heading">
        Contact <span className="highlight">Us</span>
      </h2>

      {/* Contact Container */}
      <div className="contact-card">
        {/* Left Card: Contact Information */}
        <div className="contact-info-card">
          <h3>Contact Information</h3>
          <p>Say something to start a live chat!</p>
          <ul>
            <li>
              <i className="fas fa-phone-alt contact-icon"></i>
              <a href="tel:+10123456789" className="contact-link">
                +91 8094414990
              </a>
            </li>
            <li>
              <i className="fas fa-envelope contact-icon"></i>
              <a href="mailto:info@ilearnplace.com" className="contact-link">
                info@ilearnplace.com
              </a>
            </li>
            <li>
              <i className="fas fa-map-marker-alt contact-icon"></i>
              <a
                href="https://www.google.com/maps/place/13%C2%B002'02.1%22N+77%C2%B037'56.5%22E"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                319/A, 21, Maruti Nagar Hennur Village 5th block Near Good Year English School Bangolre Karnatak -560043
              </a>
            </li>
          </ul>
        </div>

        {/* Right Card: Contact Form */}
        <div className="contact-form-card">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {formErrors.firstName && <p className="error-message">{formErrors.firstName}</p>}
              
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              {formErrors.lastName && <p className="error-message">{formErrors.lastName}</p>}
            </div>
            <div className="form-row">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {formErrors.email && <p className="error-message">{formErrors.email}</p>}
              
              <input
                type="text"
                placeholder="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
            </div>
            <textarea
              name="message"
              placeholder="Write your message..."
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {formErrors.message && <p className="error-message">{formErrors.message}</p>}
            
            <button type="submit" className="send-message-button" disabled={loading}>
              {loading ? "Sending..." : "Send Message"}
            </button>
          </form>
          {success && <p className="success-message">Message sent successfully!</p>}
          {error && <p className="error-message">Error: {error}</p>}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
