// import React from "react";
// import { useNavigate } from "react-router-dom";
// import "./ProfilePage.css";

// const ProfilePage = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     // Clear user session
//     localStorage.removeItem("token");
//     navigate("/login"); // Redirect to login page or homepage
//   };

//   return (
//     <div className="profile-page">
//       <h1>Welcome to Your Profile</h1>
//       <button className="logout-button" onClick={handleLogout}>
//         Logout
//       </button>
//     </div>
//   );
// };

// export default ProfilePage;




import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "./ProfilePage.css";

const ProfilePage = () => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch("https://almondai.in/ilpapi/create/student", {
          method: "GET",
          headers: {
            Authorization: token,
          },
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Failed to fetch profile");

        setProfile(data);
        setUpdatedProfile({ ...data.stu, ...data.add[0] });
      } catch (error) {
        console.error(error.message || "An error occurred.");
      }
    };

    fetchProfile();
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);

      const formData = new FormData();
      formData.append("photo", file);

      try {
        const response = await fetch("https://almondai.in/ilpapi/create/student", {
          method: "PUT",
          headers: {
            Authorization: token,
          },
          body: formData,
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Failed to update profile image");

        setProfile((prev) => ({
          ...prev,
          stu: { ...prev.stu, photo: data.photo }, // Update profile image URL
        }));
        alert("Profile image updated successfully!");
      } catch (error) {
        console.error(error.message || "Failed to upload image.");
        alert("Failed to update profile image. Please try again.");
      }
    }
  };

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profile-page">
      <div className="profile-header">
        {/* Profile Image Section */}
        <div className="profile-image-wrapper">
          <div className="profile-image">
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : `https://almondai.in/ilpapi${profile.stu.photo}`
              }
              alt="Profile"
              className="circular-image"
            />
            <label htmlFor="upload-image" className="edit-icon">
              <BorderColorIcon />
            </label>
            <input
              id="upload-image"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
          </div>
        </div>

        {/* Profile Edit Button */}
        <div className="edit-profile">
          <button className="cancel-button" onClick={handleEditToggle}>
            {isEditing ? "Save Changes" : "Edit Profile"}
          </button>
          {isEditing && (
            <button
              className="cancel-button"
              onClick={() => {
                setIsEditing(false);
                setUpdatedProfile({ ...profile.stu, ...profile.add[0] }); // Reset changes
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      <h1>Profile</h1>

      {/* Profile Details Section */}
      <div className="profile-details-grid">
        <div className="profile-field">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={updatedProfile.name || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
        <div className="profile-field">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={updatedProfile.email || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
        <div className="profile-field">
  <label>Contact:</label>
  <input
    type="text"
    name="contact"
    value={updatedProfile.contact || ""}
    readOnly
    className="readonly-field"
  />
</div>
        <div className="profile-field">
  <label>Gender:</label>
  {isEditing ? (
    <select
      name="gender"
      value={updatedProfile.gender || ""}
      onChange={handleChange}
      className="editable-field"
    >
      <option value="">Select Gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </select>
  ) : (
    <span className="readonly-field">{updatedProfile.gender || "N/A"}</span>
  )}
</div>
      </div>

      <h3>Address</h3>
      <div className="profile-details-grid">
        <div className="profile-field">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={updatedProfile.address || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
        <div className="profile-field">
          <label>District:</label>
          <input
            type="text"
            name="district"
            value={updatedProfile.district || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
        <div className="profile-field">
          <label>State:</label>
          <input
            type="text"
            name="state"
            value={updatedProfile.state || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
        <div className="profile-field">
          <label>Pincode:</label>
          <input
            type="text"
            name="pincode"
            value={updatedProfile.pincode || ""}
            onChange={handleChange}
            readOnly={!isEditing}
            className={`editable-field ${isEditing ? "editable" : ""}`}
          />
        </div>
      </div>

      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default ProfilePage;
