// import React, { useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./LoginPage.css";

// const LoginPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [mobile, setMobile] = useState("");
//   const [otp, setOtp] = useState("");
//   const [error, setError] = useState("");

//   const handleRequestOtp = async () => {
//     if (!mobile) {
//       setError("Please enter a valid mobile number");
//       return;
//     }

//     try {
//       const response = await fetch("https://almondai.in/ilpapi/otp/generate", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ contact: mobile }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         setIsOtpSent(true);
//         setError(""); // Clear any previous errors
//       } else {
//         setError(data.message || "Failed to send OTP");
//       }
//     } catch (err) {
//       setError("Something went wrong. Please try again.");
//     }
//   };

//   const handleVerifyOtp = async () => {
//     if (!otp || otp.length !== 6) {
//       setError("Please enter the 6-digit OTP");
//       return;
//     }

//     try {
//       const response = await fetch("https://almondai.in/ilpapi/otp/verify", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ contact: mobile, otp }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         localStorage.setItem("token", data.token); // Store JWT token

//         // Get the previous route or default to the homepage
//         const prevRoute = location.state?.from || "/";
//         navigate(prevRoute); // Redirect back to the previous page
//       } else {
//         setError(data.message || "Invalid OTP. Please try again.");
//       }
//     } catch (err) {
//       setError("Something went wrong. Please try again.");
//     }
//   };

//   return (
//     <div className="login-page">
//   <div className="login-card">
//     {/* Left Section: Image */}
//     <div className="login-card-image">
//       <img src="path-to-your-image.jpg" alt="Login Visual" />
//     </div>

//     {/* Right Section: Form */}
//     <div className="login-card-form">
//       {!isOtpSent ? (
//         <>
//           <h2>Enter Mobile Number</h2>
//           <input
//             type="text"
//             placeholder="Enter your mobile number"
//             className="login-input"
//             value={mobile}
//             onChange={(e) => setMobile(e.target.value)}
//           />
//           {error && <p className="error-text">{error}</p>}
//           <p className="terms-text">
//             By continuing, you agree to our{" "}
//             <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
//           </p>
//           <button onClick={handleRequestOtp} className="login-button">
//             Request OTP
//           </button>
//         </>
//       ) : (
//         <>
//           <h2>Enter OTP</h2>
//           <p className="otp-sent-text">OTP sent to your mobile number</p>
//           <div className="otp-container">
//             {Array.from({ length: 6 }).map((_, index) => (
//               <input
//                 key={index}
//                 type="text"
//                 maxLength="1"
//                 className="otp-input"
//                 value={otp[index] || ""}
//                 onChange={(e) => {
//                   const newOtp = otp.split("");
//                   newOtp[index] = e.target.value;
//                   setOtp(newOtp.join(""));
//                 }}
//               />
//             ))}
//           </div>
//           {error && <p className="error-text">{error}</p>}
//           <button onClick={handleVerifyOtp} className="login-button">
//             Verify
//           </button>
//           <p className="resend-text">
//             Not received your code?{" "}
//             <a href="#" onClick={handleRequestOtp}>
//               Resend code
//             </a>
//           </p>
//         </>
//       )}
//     </div>
//   </div>
// </div>

//   );
// };

// export default LoginPage;


import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoginPage.css";
import flash from "../../assets/images/flash.jpeg";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(120); // 2-minute countdown

  // Countdown timer logic
  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [isOtpSent, timer]);

  const formatTimer = () => {
    const minutes = Math.floor(timer / 60).toString().padStart(2, "0");
    const seconds = (timer % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleRequestOtp = async () => {
    if (!mobile) {
      setError("Please enter a valid mobile number");
      return;
    }

    try {
      const response = await fetch("https://almondai.in/ilpapi/otp/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contact: mobile }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsOtpSent(true);
        setTimer(120); // Reset the timer
        setError(""); // Clear any previous errors
      } else {
        setError(data.message || "Failed to send OTP");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.some((digit) => !digit)) {
      setError("Please enter the complete OTP");
      return;
    }

    try {
      const response = await fetch("https://almondai.in/ilpapi/otp/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contact: mobile, otp: otp.join("") }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("token", data.token); // Store JWT token

        // Redirect to the previous page or home if no previous location is available
        const prevRoute = location.state?.from || "/";
        console.log(prevRoute)
        navigate(prevRoute); // Redirect back to the previous page
      } else {
        setError(data.message || "Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    if (value.length > 1) return; // Prevent multiple characters in one field

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Automatically move to the next input if value is entered
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleOtpBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <div className="login-page">
      <div className="login-card">
        {/* Left Section: Image */}
        <div className="login-card-image">
          <img src={flash} alt="Login Visual" />
        </div>

        {/* Right Section: Form */}
        <div className="login-card-form">
          {!isOtpSent ? (
            <>
              <h2>Enter Mobile Number</h2>
              <input
                type="text"
                placeholder="Enter your mobile number"
                className="login-input"
                value={mobile}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Allow only digits
                  if (value.length <= 10) {
                    setMobile(value); // Restrict input to 10 digits
                  }
                }}
              />
              {error && <p className="error-text">{error}</p>}
              <p className="terms-text">
                By continuing, you agree to our{" "}
                <a href="/pp">Terms of Use</a> and <a href="/pp">Privacy Policy</a>.
              </p>
              <button onClick={handleRequestOtp} className="login-button">
                Request OTP
              </button>
            </>
          ) : (
            <>
              <h2>Enter OTP</h2>
              <p className="otp-sent-text">OTP sent to your mobile number</p>
              <div className="otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    className="otp-input"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpBackspace(e, index)}
                  />
                ))}
              </div>
              {error && <p className="error-text">{error}</p>}
              <button onClick={handleVerifyOtp} className="login-button">
                Verify
              </button>
              {timer > 0 ? (
                <p className="resend-text">Resend code in {formatTimer()}</p>
              ) : (
                <p className="resend-text">
                  Not received your code?{" "}
                  <a href="#" onClick={handleRequestOtp}>
                    Resend code
                  </a>
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;



// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./LoginPage.css";
// import flash from "../../assets/images/flash.jpeg";

// const LoginPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [mobile, setMobile] = useState("");
//   const [otp, setOtp] = useState(Array(6).fill(""));
//   const [error, setError] = useState("");
//   const [timer, setTimer] = useState(120); // 2-minute countdown

//   useEffect(() => {
//     if (isOtpSent && timer > 0) {
//       const countdown = setInterval(() => {
//         setTimer((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(countdown);
//     }
//   }, [isOtpSent, timer]);

//   const formatTimer = () => {
//     const minutes = Math.floor(timer / 60).toString().padStart(2, "0");
//     const seconds = (timer % 60).toString().padStart(2, "0");
//     return `${minutes}:${seconds}`;
//   };

//   const handleRequestOtp = async () => {
//     if (!mobile) {
//       setError("Please enter a valid mobile number");
//       return;
//     }

//     try {
//       const response = await fetch("https://almondai.in/ilpapi/otp/generate", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ contact: mobile }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         setIsOtpSent(true);
//         setTimer(120);
//         setError("");
//       } else {
//         setError(data.message || "Failed to send OTP");
//       }
//     } catch (err) {
//       setError("Something went wrong. Please try again.");
//     }
//   };

//   const handleVerifyOtp = async () => {
//     if (otp.some((digit) => !digit)) {
//       setError("Please enter the complete OTP");
//       return;
//     }

//     try {
//       const response = await fetch("https://almondai.in/ilpapi/otp/verify", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ contact: mobile, otp: otp.join("") }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         localStorage.setItem("token", data.token);

//         console.log("path",location.state?.from?.pathname)
//         const prevRoute = location.state?.from?.pathname || "/";
//         console.log("path",location.state?.from?.pathname)
//         navigate(prevRoute, { replace: true });
//       } else {
//         setError(data.message || "Invalid OTP. Please try again.");
//       }
//     } catch (err) {
//       setError("Something went wrong. Please try again.");
//     }
//   };

//   const handleOtpChange = (e, index) => {
//     const value = e.target.value.replace(/\D/g, "");
//     if (value.length > 1) return;

//     const newOtp = [...otp];
//     newOtp[index] = value;
//     setOtp(newOtp);

//     if (value && index < otp.length - 1) {
//       document.getElementById(`otp-input-${index + 1}`).focus();
//     }
//   };

//   const handleOtpBackspace = (e, index) => {
//     if (e.key === "Backspace" && !otp[index] && index > 0) {
//       document.getElementById(`otp-input-${index - 1}`).focus();
//     }
//   };

//   return (
//     <div className="login-page">
//       <div className="login-card">
//         <div className="login-card-image">
//           <img src={flash} alt="Login Visual" />
//         </div>
//         <div className="login-card-form">
//           {!isOtpSent ? (
//             <>
//               <h2>Enter Mobile Number</h2>
//               <input
//                 type="text"
//                 placeholder="Enter your mobile number"
//                 className="login-input"
//                 value={mobile}
//                 onChange={(e) => {
//                   const value = e.target.value.replace(/\D/g, "");
//                   if (value.length <= 10) setMobile(value);
//                 }}
//               />
//               {error && <p className="error-text">{error}</p>}
//               <p className="terms-text">
//                 By continuing, you agree to our{" "}
//                 <a href="/pp">Terms of Use</a> and <a href="/pp">Privacy Policy</a>.
//               </p>
//               <button onClick={handleRequestOtp} className="login-button">
//                 Request OTP
//               </button>
//             </>
//           ) : (
//             <>
//               <h2>Enter OTP</h2>
//               <p className="otp-sent-text">OTP sent to your mobile number</p>
//               <div className="otp-container">
//                 {otp.map((digit, index) => (
//                   <input
//                     key={index}
//                     id={`otp-input-${index}`}
//                     type="text"
//                     maxLength="1"
//                     className="otp-input"
//                     value={digit}
//                     onChange={(e) => handleOtpChange(e, index)}
//                     onKeyDown={(e) => handleOtpBackspace(e, index)}
//                   />
//                 ))}
//               </div>
//               {error && <p className="error-text">{error}</p>}
//               <button onClick={handleVerifyOtp} className="login-button">
//                 Verify
//               </button>
//               {timer > 0 ? (
//                 <p className="resend-text">Resend code in {formatTimer()}</p>
//               ) : (
//                 <p className="resend-text">
//                   Not received your code?{" "}
//                   <a href="#" onClick={handleRequestOtp}>
//                     Resend code
//                   </a>
//                 </p>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;
