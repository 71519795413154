// import React from "react";
// import { Box, Typography } from "@mui/material";
// import SwipeableViews from "react-swipeable-views";
// import "./Taskslider.css"; // Custom CSS for styles

// const sliderImages = [
//   { url: "/images/slider1.png", alt: "Slider Image 1" },
//   { url: "/images/slider2.png", alt: "Slider Image 2" },
//   { url: "/images/slider3.png", alt: "Slider Image 3" },
// ];

// const ResponsiveSliderPage = () => {
//   return (
//     <Box
//       sx={{
//         width: "100%",
//         minHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         overflow: "hidden",
//       }}
//     >
//       {/* Heading */}
//       <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
//         <Typography
//           variant="h4"
//           component="h1"
//           sx={{ fontWeight: "bold", mb: 2 }}
//         >
//           Tasks in Kit
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: "80%",
//             margin: "0 auto",
//           }}
//         />
//       </Box>

//       {/* Slider Section */}
//       <SwipeableViews enableMouseEvents style={{ width: "100%" }}>
//         {sliderImages.map((slide, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "100%",
//               height: "calc(100vh - 80px)", // Adjust height based on heading
//               backgroundColor: "#f4f6f7",
//             }}
//           >
//             <img
//               src={slide.url}
//               alt={slide.alt}
//               style={{
//                 maxWidth: "100%",
//                 maxHeight: "100%",
//                 objectFit: "contain",
//               }}
//             />
//           </Box>
//         ))}
//       </SwipeableViews>
//     </Box>
//   );
// };

// export default ResponsiveSliderPage;



// import React from "react";
// import { Box, Typography, Card, CardMedia } from "@mui/material";
// import SwipeableViews from "react-swipeable-views"; // For the slider functionality
// import "./Taskslider.css"; // Custom CSS for styles

// // Dynamic Props or API data will come here
// const ResponsiveSliderPage = ({ sliderImages }) => {
//   // Media query for mobile view
//   const isMobile = useMediaQuery("(max-width:600px)");

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         minHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         overflow: "hidden",
//         padding: isMobile ? "0 10px" : "0", // Add padding for mobile view
//       }}
//     >
//       {/* Heading */}
//       <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
//         <Typography
//           variant="h4"
//           component="h1"
//           sx={{
//             fontWeight: "bold",
//             mb: 2,
//             fontSize: isMobile ? "1.5rem" : "2rem", // Adjust font size for mobile
//           }}
//         >
//           Tasks in Kit
//         </Typography>
//         <Box
//           sx={{
//             borderBottom: "2px solid #f4f6f7",
//             width: isMobile ? "90%" : "80%", // Adjust border width based on screen size
//             margin: "0 auto",
//           }}
//         />
//       </Box>

//       {/* Slider Section */}
//       <Card sx={{ height: '100%' ,overflow: 'hidden'}}>
//   <SwipeableViews index={sliderIndex} onChangeIndex={(index) => setSliderIndex(index)} enableMouseEvents>
//     {sliderImages.map((slide, index) => (
//       <CardMedia
//         key={index}
//         component="img"
//         src={slide.url}
//         alt={`Slider Image ${index + 1}`}
//         sx={{
//           height: '100%',
//           width: '100%',
//           objectFit: 'cover', // Ensures the image fits within the Card
//           display: 'block', 
//         }}
//       />
//     ))}
//   </SwipeableViews>
// </Card>
//     </Box>
//   );
// };

// export default ResponsiveSliderPage;
// <Card sx={{ height: '100%' ,overflow: 'hidden'}}>
//   <SwipeableViews index={sliderIndex} onChangeIndex={(index) => setSliderIndex(index)} enableMouseEvents>
//     {sliderImages.map((slide, index) => (
//       <CardMedia
//         key={index}
//         component="img"
//         src={slide.url}
//         alt={`Slider Image ${index + 1}`}
//         sx={{
//           height: '100%',
//           width: '100%',
//           objectFit: 'cover', // Ensures the image fits within the Card
//           display: 'block', 
//         }}
//       />
//     ))}
//   </SwipeableViews>
// </Card>


import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import SwipeableViews from "react-swipeable-views"; // For the slider functionality

// TaskSlider component should accept props
const TaskSlider = ({ sliderImages }) => {
  const [sliderIndex, setSliderIndex] = useState(0); // State to track the slider index

  // Auto-slide the images every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSliderIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
    }, 3000); // Change image every 3 seconds (3000ms)

    // Clear the interval when the component is unmounted or when the sliderImages change
    return () => clearInterval(interval);
  }, [sliderImages.length]);

  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2 }} align="center">
        Task In Kit
      </Typography>

      {/* Slider */}
      <SwipeableViews
        index={sliderIndex}
        onChangeIndex={(index) => setSliderIndex(index)} // Update slider index on manual change
      >
        {sliderImages.map((slide, index) => (
          <Box key={index} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Card sx={{ maxWidth: "100%", boxShadow: 3 }}>
              <CardMedia
                component="img"
                image={slide.url}
                alt={slide.alt}
                sx={{ height: "auto", objectFit: "contain" }}
              />
            </Card>
          </Box>
        ))}
      </SwipeableViews>
    </Box>
  );
};

export default TaskSlider;
