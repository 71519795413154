import React from "react";
import "./About.css";
import FeaturesPage from "./FeaturesPage";
import TestimonialsPage from "./TestimonialsPage";
import ServicesPage from "./ServicesPages";
import ShopWithPage from "./ShopWithPage";

const About = () => {
  const handleAppButtonClick = () => {
    // Open the provided link in a new tab
    window.open(
      "https://play.google.com/store/apps/details?id=com.iLearnPlace.stemlearning&pcampaignid=web_share",
      "_blank"
    );
  };
  return (
    <section className="about" id="about">
      {/* Heading Section */}
      <h2 className="about-heading">
        About <span className="highlight">iLearnPlace</span>
      </h2>

      {/* About Content Section */}
      <div className="about-content">
        {/* Left Section: Text */}
        <div className="about-text">
          <p>
          iLearnPlace is a leading STEM learning and robotics company dedicated to making practical and hands-on education fun and accessible for every child. We focus on fostering creativity, critical thinking, and problem-solving by providing engaging learning experiences through innovative kits and interactive STEM sessions. Our mission is to inspire curiosity and empower the leaders of tomorrow through practical, real-world applications.
          </p>
        </div>

        {/* Right Section: Video */}
        {/* 
        <div className="about-video">
          <video controls width="100%" className="video-player">
            <source
              src="https://youtu.be/MXcqxfVoYMY?feature=shared" // Replace with your video URL
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        */}
        <div className="about-video">
          <iframe
            className="video-player"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/MXcqxfVoYMY" // Embed link for YouTube video
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="cta-container">
        <div className="cta-text">Seamless Solutions, Just a Tap Away!</div>
        <button className="cta-button" onClick={handleAppButtonClick}>
          <span className="cta-icon">📱</span> Android APP
        </button>
      </div>

      {/* Include Other Components */}
      <FeaturesPage />
      <TestimonialsPage />
      <ServicesPage />
      <ShopWithPage />
    </section>
  );
};

export default About;
