// import {
//     FETCH_ADDRESSES_REQUEST,
//     FETCH_ADDRESSES_SUCCESS,
//     FETCH_ADDRESSES_FAILURE,
//     EDIT_ADDRESS,
//   } from "./addressActions";
  
//   const initialState = {
//     loading: false,
//     addresses: [],
//     error: null,
//   };
  
//   const addressReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_ADDRESSES_REQUEST:
//         return { ...state, loading: true };
//       case FETCH_ADDRESSES_SUCCESS:
//         return { ...state, loading: false, addresses: action.payload, error: null };
//       case FETCH_ADDRESSES_FAILURE:
//         return { ...state, loading: false, error: action.payload };
//       case EDIT_ADDRESS:
//         return {
//           ...state,
//           addresses: state.addresses.map((address) =>
//             address.id === action.payload.id ? action.payload : address
//           ),
//         };
//       default:
//         return state;
//     }
//   };
  
//   export default addressReducer;
  


import {
    FETCH_ADDRESSES_REQUEST,
    FETCH_ADDRESSES_SUCCESS,
    FETCH_ADDRESSES_FAILURE,
    ADD_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_SUCCESS,
  } from './addressActions';
  
  // Initial state
  const initialState = {
    loading: false,
    addresses: [],
    error: null,
  };
  
  // Reducer function
  const addressReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADDRESSES_REQUEST:
        return { ...state, loading: true };
      case FETCH_ADDRESSES_SUCCESS:
        return { ...state, loading: false, addresses: action.payload };
      case FETCH_ADDRESSES_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case ADD_ADDRESS_SUCCESS:
        return { ...state, addresses: [...state.addresses, action.payload] };
      case UPDATE_ADDRESS_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.map((address) =>
            address.id === action.payload.id ? action.payload : address
          ),
        };
      case DELETE_ADDRESS_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.filter((address) => address.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default addressReducer;
  