// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Autoplay } from "swiper/modules"; // Include Autoplay and Pagination
// import "swiper/css"; // Swiper core styles
// import "swiper/css/pagination"; // Swiper pagination styles
// import "./BannerSlider.css"; // Import custom styles
// import r1 from "../../assets/images/b1.png";
// import r2 from "../../assets/images/b2.png"

// // Sample banner data with image URLs
// const banners = [
//   {
//     id: 1,
//     image: r1, // Replace with your actual image URL
//   },
//   {
//     id: 2,
//     image: r2, // Replace with your actual image URL
//   },
// ];

// const BannerSlider = () => {
//   return (
//     <Swiper
//       slidesPerView={1} // Show only 1 slide at a time
//       loop={true} // Enable infinite loop
//       autoplay={{ delay: 3000 }} // Auto-slide every 3 seconds
//       pagination={{ clickable: true }} // Dots below for slide indication
//       modules={[Pagination, Autoplay]} // Include Autoplay and Pagination
//       className="banner-slider"
//     >
//       {banners.map((banner) => (
//         <SwiperSlide key={banner.id} className="banner-slide">
//           <div className="banner-image">
//             <img src={banner.image} alt={`Slide ${banner.id}`} />
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default BannerSlider;




import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Include Autoplay and Pagination
import "swiper/css"; // Swiper core styles
import "swiper/css/pagination"; // Swiper pagination styles
import "./BannerSlider.css"; // Import custom styles
import r1 from "../../assets/images/b1.png";
import r2 from "../../assets/images/b2.png";

const banners = [
  {
    id: 1,
    type: "image", // Type is "image"
    source: r1, // Image source
  },
  {
    id: 2,
    type: "image", // Type is "image"
    source: r2, // Image source
  },
  {
    id: 3,
    type: "youtube", // Type is "youtube"
    source: "https://www.youtube.com/embed/k1LrNItkyvY?si=_9NqWkfk6pEQIL6R", // YouTube video URL
  },
  {
    id: 4,
    type: "youtube", // Type is "youtube"
    source: "https://www.youtube.com/embed/a90-BjbfLUg?si=BCvjkOh4petqxfsF", // YouTube video URL
  },
  {
    id: 5,
    type: "youtube", // Type is "youtube"
    source: "https://www.youtube.com/embed/FJJQt-V0cD8?si=xL1IrN_Dt_5hn-ZW", // YouTube video URL
  },
  {
    id: 6,
    type: "youtube", // Type is "youtube"
    source: "https://www.youtube.com/embed/KHi6X_Srplc?si=kozpSH_LFam5QP-q", // YouTube video URL
  },
  {
    id: 7,
    type: "youtube", // Type is "youtube"
    source: "https://www.youtube.com/embed/sOOysD0sz9E?si=fiMKrI5N_I7Tj5-i", // YouTube video URL
  },
];

const BannerSlider = () => {
  const swiperRef = useRef(null); // Reference to control the swiper instance

  const handleYouTubePlayPause = (event) => {
    const playerState = event.data; // YouTube player state

    if (playerState === 1) {
      // Video is playing
      swiperRef.current?.autoplay.stop();
    } else if (playerState === 2 || playerState === 0) {
      // Video is paused or ended
      swiperRef.current?.autoplay.start();
    }
  };

  const onYouTubeIframeReady = (iframeId) => {
    const player = new window.YT.Player(iframeId, {
      events: {
        onStateChange: handleYouTubePlayPause,
      },
    });
  };

  React.useEffect(() => {
    // Load the YouTube API script if not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      banners
        .filter((banner) => banner.type === "youtube")
        .forEach((banner) => onYouTubeIframeReady(`youtube-${banner.id}`));
    }
  }, []);

  React.useEffect(() => {
    // Once API is loaded, attach YouTube event listeners
    window.onYouTubeIframeAPIReady = () => {
      banners
        .filter((banner) => banner.type === "youtube")
        .forEach((banner) => onYouTubeIframeReady(`youtube-${banner.id}`));
    };
  }, []);

  return (
    <Swiper
      slidesPerView={1} // Show only 1 slide at a time
      loop={false} // Enable infinite loop
      // autoplay={{ delay: 3000 }} // Auto-slide every 3 seconds
      pagination={{ clickable: true }} // Dots below for slide indication
      modules={[Pagination]} // Include Autoplay and Pagination
      className="banner-slider"
      onSwiper={(swiper) => (swiperRef.current = swiper)} // Store swiper instance
    >
      {banners.map((banner) => (
        <SwiperSlide key={banner.id} className="banner-slide">
          <div className="banner-media">
            {banner.type === "image" ? (
              <img
                src={banner.source}
                alt={`Slide ${banner.id}`}
                className="STEM-content"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : banner.type === "youtube" ? (
              <iframe
                id={`youtube-${banner.id}`}
                className="banner-content"
                src={`${banner.source}`} // Enable JS API
                title={`YouTube Video ${banner.id}`}
                frameBorder="0"
                style={{
                  width: "100%",
                  height: "100%", // Match image height
                  objectFit: "cover",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : null}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSlider;
