// import React, { useState } from "react";
// import {
//   Box,
//   Grid,
//   Typography,
//   Card,
//   CardMedia,
//   Button,
// } from "@mui/material";
// import { PlayCircleOutline } from "@mui/icons-material";

// const ProductDetailsPage = () => {
//   const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

//   // Mock product data
//   const productData = {
//     title: "STEM Learning Kit - Military Jeep",
//     price: 3499,
//     oldPrice: 3999,
//     discount: 13,
//     rating: 4.8,
//     features: [
//       "🚀 50+ Experiments & 11+ STEM Tasks: Fun, hands-on learning for kids.",
//       "🧠 Develop Critical Thinking: Boost creativity and problem-solving skills.",
//       "🎮 Gamified Learning: Multiple challenges keep kids engaged and motivated.",
//     ],
//     specifications: [
//       { icon: "6+", label: "6+ year" },
//       { icon: "🔄", label: "Easy Replacement" },
//       { icon: "📦", label: "Delivery in 5 days" },
//       { icon: "🚚", label: "Free Delivery" },
//     ],
//     gallery: [
//       { type: "image", url: "/images/product-main.jpg" },
//       { type: "video", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//       { type: "image", url: "/images/product-1.jpg" },
//       { type: "image", url: "/images/product-2.jpg" },
//       { type: "image", url: "/images/product-3.jpg" },
//     ],
//   };

//   const handleThumbnailClick = (index) => {
//     setSelectedMediaIndex(index);
//   };

//   const renderMainMedia = (media) => {
//     if (media.type === "image") {
//       return (
//         <CardMedia
//           component="img"
//           src={media.url}
//           alt="Main Media"
//           sx={{ width: "100%", height: "400px", objectFit: "contain" }}
//         />
//       );
//     }
//     return (
//       <iframe
//         src={media.url}
//         title="Video"
//         width="100%"
//         height="400px"
//         allowFullScreen
//         style={{ border: "none" }}
//       />
//     );
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Grid container spacing={2}>
//         {/* Left Section */}
//         <Grid item xs={12} md={6}>
//           <Grid container spacing={2}>
//             {/* Left-Left Section: Thumbnails */}
//             <Grid item xs={3}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   overflowY: "auto",
//                   maxHeight: "400px",
//                   gap: 1,
//                 }}
//               >
//                 {productData.gallery.map((media, index) => (
//                   <Card
//                     key={index}
//                     onClick={() => handleThumbnailClick(index)}
//                     sx={{
//                       cursor: "pointer",
//                       border:
//                         selectedMediaIndex === index
//                           ? "2px solid #3498db"
//                           : "1px solid #ccc",
//                     }}
//                   >
//                     {media.type === "image" ? (
//                       <CardMedia
//                         component="img"
//                         src={media.url}
//                         alt={`Thumbnail ${index}`}
//                         sx={{ height: "80px", objectFit: "cover" }}
//                       />
//                     ) : (
//                       <Box sx={{ position: "relative" }}>
//                         <CardMedia
//                           component="img"
//                           src="/images/video-placeholder.jpg"
//                           alt="Video Placeholder"
//                           sx={{ height: "80px", objectFit: "cover" }}
//                         />
//                         <PlayCircleOutline
//                           sx={{
//                             position: "absolute",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translate(-50%, -50%)",
//                             color: "white",
//                             fontSize: 24,
//                           }}
//                         />
//                       </Box>
//                     )}
//                   </Card>
//                 ))}
//               </Box>
//             </Grid>

//             {/* Left-Right Section: Large Media Preview */}
//             <Grid item xs={9}>
//               <Box>{renderMainMedia(productData.gallery[selectedMediaIndex])}</Box>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Right Section */}
//         <Grid item xs={12} md={6}>
//           <Box>
//             <Typography variant="h4" gutterBottom>
//               {productData.title}
//             </Typography>
//             <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//               <Typography variant="h5" color="primary" sx={{ mr: 2 }}>
//                 ₹{productData.price}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 color="textSecondary"
//                 sx={{ textDecoration: "line-through", mr: 2 }}
//               >
//                 ₹{productData.oldPrice}
//               </Typography>
//               <Typography variant="body1" color="green">
//                 {productData.discount}% off
//               </Typography>
//             </Box>

//             <Typography variant="h6" gutterBottom>
//               Key Features:
//             </Typography>
//             <ul>
//               {productData.features.map((feature, index) => (
//                 <li key={index}>
//                   <Typography variant="body1">{feature}</Typography>
//                 </li>
//               ))}
//             </ul>

//             <Grid container spacing={2} sx={{ mt: 2 }}>
//               {productData.specifications.map((spec, index) => (
//                 <Grid item xs={6} sm={3} key={index}>
//                   <Box textAlign="center">
//                     <Typography variant="h5">{spec.icon}</Typography>
//                     <Typography variant="body2">{spec.label}</Typography>
//                   </Box>
//                 </Grid>
//               ))}
//             </Grid>

//             <Button
//               variant="contained"
//               color="primary"
//               sx={{ mt: 3, padding: "10px 20px" }}
//             >
//               Buy Now
//             </Button>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default ProductDetailsPage;




// import React, { useState } from "react";
// import Footer from "../Footer/Footer";
// import {
//   Box,
//   Grid,
//   Typography,
//   Card,
//   CardMedia,
//   Button,
//   useMediaQuery,
// } from "@mui/material";
// import SwipeableViews from "react-swipeable-views";
// import { PlayCircleOutline } from "@mui/icons-material";
// import StarRating from "./Rating";
// import ResponsiveSliderPage from "./Taskslider"
// import ProductDescriptionPage from "./ProductDescriptionPage"
// import FeaturesPage from "./FeaturesPage"

// const ProductDetailsPage = () => {
//   const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

//   // Media query to detect mobile view
//   const isMobile = useMediaQuery("(max-width:600px)");

//   // Mock product data
//   const productData = {
//     title: "STEM Learning Kit - Military Jeep",
//     price: 3499,
//     oldPrice: 3999,
//     discount: 13,
//     rating: 4.8,
//     features: [
//       "🚀 50+ Experiments & 11+ STEM Tasks: Fun, hands-on learning for kids.",
//       "🧠 Develop Critical Thinking: Boost creativity and problem-solving skills.",
//       "🎮 Gamified Learning: Multiple challenges keep kids engaged and motivated.",
//     ],
//     specifications: [
//       { icon: "6+", label: "6+ year" },
//       { icon: "🔄", label: "Easy Replacement" },
//       { icon: "📦", label: "Delivery in 5 days" },
//       { icon: "🚚", label: "Free Delivery" },
//     ],
//     gallery: [
//       { type: "image", url: "/images/product-main.jpg" },
//       { type: "video", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//       { type: "image", url: "/images/product-1.jpg" },
//       { type: "image", url: "/images/product-2.jpg" },
//       { type: "image", url: "/images/product-3.jpg" },
//     ],
//   };

//   const handleThumbnailClick = (index) => {
//     setSelectedMediaIndex(index);
//   };

//   const renderMainMedia = (media) => {
//     if (media.type === "image") {
//       return (
//         <CardMedia
//           component="img"
//           src={media.url}
//           alt="Main Media"
//           sx={{ width: "100%", height: "400px", objectFit: "contain" }}
//         />
//       );
//     }
//     return (
//       <iframe
//         src={media.url}
//         title="Video"
//         width="100%"
//         height="400px"
//         allowFullScreen
//         style={{ border: "none" }}
//       />
//     );
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Grid container spacing={2}>
//         {/* Left Section */}
//         <Grid item xs={12} md={6}>
//           <Grid container spacing={2}>
//             {/* Left-Left Section: Thumbnails */}
//             {!isMobile && (
//               <Grid item xs={3}>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     overflowY: "auto",
//                     maxHeight: "400px",
//                     gap: 1,
//                   }}
//                 >
//                   {productData.gallery.map((media, index) => (
//                     <Card
//                       key={index}
//                       onClick={() => handleThumbnailClick(index)}
//                       sx={{
//                         cursor: "pointer",
//                         border:
//                           selectedMediaIndex === index
//                             ? "2px solid #3498db"
//                             : "1px solid #ccc",
//                       }}
//                     >
//                       {media.type === "image" ? (
//                         <CardMedia
//                           component="img"
//                           src={media.url}
//                           alt={`Thumbnail ${index}`}
//                           sx={{ height: "80px", objectFit: "cover" }}
//                         />
//                       ) : (
//                         <Box sx={{ position: "relative" }}>
//                           <CardMedia
//                             component="img"
//                             src="/images/video-placeholder.jpg"
//                             alt="Video Placeholder"
//                             sx={{ height: "80px", objectFit: "cover" }}
//                           />
//                           <PlayCircleOutline
//                             sx={{
//                               position: "absolute",
//                               top: "50%",
//                               left: "50%",
//                               transform: "translate(-50%, -50%)",
//                               color: "white",
//                               fontSize: 24,
//                             }}
//                           />
//                         </Box>
//                       )}
//                     </Card>
//                   ))}
//                 </Box>
//               </Grid>
//             )}

//             {/* Left-Right Section: Large Media Preview */}
//             <Grid item xs={isMobile ? 12 : 9}>
//               {isMobile ? (
//                 <SwipeableViews
//                   index={selectedMediaIndex}
//                   onChangeIndex={(index) => setSelectedMediaIndex(index)}
//                 >
//                   {productData.gallery.map((media, index) => (
//                     <Box key={index} sx={{ width: "100%" }}>
//                       {renderMainMedia(media)}
//                     </Box>
//                   ))}
//                 </SwipeableViews>
//               ) : (
//                 <Box>{renderMainMedia(productData.gallery[selectedMediaIndex])}</Box>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Right Section */}
//         <Grid item xs={12} md={6}>
//           <Box>
//             <Typography variant="h4" gutterBottom>
//               {productData.title}
//             </Typography>
//             {/* <StarRating/> */}
//             <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//               <Typography variant="h5" color="primary" sx={{ mr: 2 }}>
//                 ₹{productData.price}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 color="textSecondary"
//                 sx={{ textDecoration: "line-through", mr: 2 }}
//               >
//                 ₹{productData.oldPrice}
//               </Typography>
//               <Typography variant="body1" color="green">
//                 {productData.discount}% off
//               </Typography>
//             </Box>

//             <Typography variant="h6" gutterBottom>
//               Key Features:
//             </Typography>
//             <ul>
//               {productData.features.map((feature, index) => (
//                 <li key={index}>
//                   <Typography variant="body1">{feature}</Typography>
//                 </li>
//               ))}
//             </ul>

//             <Grid container spacing={2} sx={{ mt: 2 }}>
//               {productData.specifications.map((spec, index) => (
//                 <Grid item xs={6} sm={3} key={index}>
//                   <Box textAlign="center">
//                     <Typography variant="h5">{spec.icon}</Typography>
//                     <Typography variant="body2">{spec.label}</Typography>
//                   </Box>
//                 </Grid>
//               ))}
//             </Grid>

//             <Button
//               variant="contained"
//               color="primary"
//               sx={{ mt: 3, padding: "10px 20px" }}
//             >
//               Buy Now
//             </Button>
//           </Box>
//         </Grid>
//           <ResponsiveSliderPage/>
//       </Grid>
//           <ProductDescriptionPage/>
//           <FeaturesPage/>
//           <Footer/>
//     </Box>
//   );
// };

// export default ProductDetailsPage;



// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams, useNavigate, useLocation } from "react-router-dom";
// import {
//   Box,
//   Grid,
//   Typography,
//   Card,
//   CardMedia,
//   Button,
//   useMediaQuery,
// } from "@mui/material";
// import SwipeableViews from "react-swipeable-views";
// import { PlayCircleOutline } from "@mui/icons-material";
// import { fetchProductDetails } from "../../features/products/purchaseSlice"; // Redux slice
// import ResponsiveSliderPage from "./Taskslider";
// import ProductDescriptionPage from "./ProductDescriptionPage";
// import FeaturesPage from "./FeaturesPage";
// import decryptURI from "../modifier/decryptURI";
// import StarRating from "./Rating";

// const ProductDetailsPage = () => {
//   const { levelCode } = useParams(); // Get levelCode from URL
//   const { state } = useLocation(); // Get the state passed via navigate
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();
//   useEffect(() => {
//     console.log("State from navigate:", state); // Print the state object
//   }, [state]);

//   const { productDetails, status, error } = useSelector(
//     (state) => state.productDetails
//   );

//   const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
//   const [showAll, setShowAll] = useState(false); // For toggling key features
//   const isMobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     if (state) {
//       dispatch(fetchProductDetails(state.levelCode)); // Fetch product details using levelCode
//     }
//   }, [dispatch, state.levelCode]);

//   const handleThumbnailClick = (index) => {
//     setSelectedMediaIndex(index);
//   };

//   const renderMainMedia = (media) => {
//     if (media?.type === "image") {
//       return (
//         <CardMedia
//           component="img"
//           src={decryptURI(media.url)}
//           alt="Main Media"
//           sx={{ width: "100%", height: "400px", objectFit: "contain" }}
//         />
//       );
//     }
//     return (
//       <iframe
//         src={media?.url}
//         title="Video"
//         width="100%"
//         height="400px"
//         allowFullScreen
//         style={{ border: "none" }}
//       />
//     );
//   };

//   const handleBuyNow = () => {
//     const token = localStorage.getItem("token"); // Check if the user is logged in
//     if (token) {
//       // If logged in, navigate to /checkout with state
//       navigate(`/checkout`, {
//         state: {
//           productName: productDetails.level.level_name,
//           productCode: productDetails.level.level_code,
//           productPrice: productDetails.level.price,
//           productDiscount: productDetails.level.discount,
//           productDescription: productDetails.level.key_description,
//           productMedia: productDetails.media_by_category.box,
//           productSpecifications: productDetails.specifications,
//           productLevel: productDetails.level,
//           productTitle: productDetails.title,
//         },
//       });
//     } else {
//       // If not logged in, redirect to login and pass the current page route as `from`
//       navigate(`/login`, {
//         state: {
//           from: location.pathname, // Save the current page the user was on
//         },
//       });
//     }
//   };

//   const handleTryNow = () => {
//     navigate(`/tasks/${levelCode}`); 
//   };

//   if (status === "loading") {
//     return <Typography>Loading product details...</Typography>;
//   }

//   if (status === "failed" || error) {
//     return <Typography>Error: {error || "Failed to load product details."}</Typography>;
//   }

//   if (!productDetails) {
//     return <Typography>No product details available.</Typography>;
//   }

//   // Determine the key features to display
//   const featuresToDisplay = showAll
//     ? productDetails.level.key_features
//     : productDetails.level.key_features.slice(0, 4);

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Grid container spacing={2}>
//         {/* Left Section */}
//         <Grid item xs={12} md={6}>
//           <Grid container spacing={2}>
//             {/* Left-Left Section: Thumbnails */}
//             {!isMobile && productDetails && productDetails.media_by_category?.box && (
//               <Grid item xs={3}>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     overflowY: "auto",
//                     maxHeight: "400px",
//                     gap: 1,
//                   }}
//                 >
//                   {productDetails.media_by_category.box.map((media, index) => {
//                     const imageUrl = decryptURI(media.media_url);
//                     return (
//                       <Card
//                         key={index}
//                         onClick={() => handleThumbnailClick(index)}
//                         sx={{
//                           cursor: "pointer",
//                           border: selectedMediaIndex === index ? "2px solid #3498db" : "1px solid #ccc",
//                         }}
//                       >
//                         {media.media_type === "image" ? (
//                           <CardMedia
//                             component="img"
//                             src={imageUrl} // Use the decrypted URL
//                             alt={`Thumbnail ${index}`}
//                             sx={{ height: "80px", objectFit: "cover" }}
//                           />
//                         ) : (
//                           <Box sx={{ position: "relative" }}>
//                             <CardMedia
//                               component="img"
//                               src="/images/video-placeholder.jpg"
//                               alt="Video Placeholder"
//                               sx={{ height: "80px", objectFit: "cover" }}
//                             />
//                             <PlayCircleOutline
//                               sx={{
//                                 position: "absolute",
//                                 top: "50%",
//                                 left: "50%",
//                                 transform: "translate(-50%, -50%)",
//                                 color: "white",
//                                 fontSize: 24,
//                               }}
//                             />
//                           </Box>
//                         )}
//                       </Card>
//                     );
//                   })}
//                 </Box>
//               </Grid>
//             )}

//             {/* Left-Right Section: Large Media Preview */}
//             <Grid item xs={isMobile ? 12 : 9}>
//               {isMobile ? (
//                 <SwipeableViews
//                   index={selectedMediaIndex}
//                   onChangeIndex={(index) => setSelectedMediaIndex(index)}
//                 >
//                   {productDetails.media_by_category.box.map((media, index) => (
//                     <Box key={index} sx={{ width: "100%" }}>
//                       <CardMedia
//                         component="img"
//                         src={decryptURI(media.media_url)} // Use the decrypted URL
//                         sx={{ height: "100%", objectFit: "cover" }}
//                       />
//                     </Box>
//                   ))}
//                 </SwipeableViews>
//               ) : (
//                 <Box>
//                   <CardMedia
//                     component="img"
//                     src={decryptURI(productDetails.media_by_category.box[selectedMediaIndex].media_url)} // Use the decrypted URL
//                     sx={{ height: "100%", objectFit: "cover" }}
//                   />
//                 </Box>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Right Section */}
//         <Grid item xs={12} md={6}>
//           <Box>
//             <Typography variant="h4" gutterBottom>
//               STEM LEARNING KIT- {productDetails.level.level_name}
//             </Typography>
//             <StarRating rating={4.8} />

//             <Typography variant="h6" gutterBottom>
//               Key Features:
//             </Typography>
//             <ul>
//               {productDetails.level.key_features && Array.isArray(productDetails.level.key_features) ? (
//                 featuresToDisplay.map((feature, index) => (
//                   <li key={index}>
//                     <Typography variant="body1">{feature}</Typography>
//                   </li>
//                 ))
//               ) : (
//                 <Typography variant="body2" color="textSecondary">
//                   No key features available.
//                 </Typography>
//               )}
//             </ul>

//             {productDetails.level.key_features && productDetails.level.key_features.length > 4 && (
//               <Button variant="text" onClick={() => setShowAll(!showAll)}>
//                 {showAll ? 'Show Less' : 'Read More'}
//               </Button>
//             )}

//             <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//               <Typography variant="h5" color="primary" sx={{ mr: 2, fontSize: "40px" }}>
//                 ₹{Math.round(productDetails.level.price - (productDetails.level.price * (productDetails.level.discount || 0)) / 100)} {/* Discounted Price */}
//               </Typography>
//               <Typography variant="body1" color="textSecondary" sx={{ textDecoration: "line-through", mr: 2, fontSize: "30px" }}>
//                 ₹{productDetails.level.price} {/* Original Price */}
//               </Typography>
//               <Typography variant="body1" color="green" sx={{ mr: 2, fontSize: "20px" }}>
//                 {Math.round(productDetails.level.discount)}% off
//               </Typography>
//             </Box>

//             <Grid container spacing={2} sx={{ mt: 2 }}>
//               {productDetails.specifications?.map((spec, index) => (
//                 <Grid item xs={6} sm={3} key={index}>
//                   <Box textAlign="center">
//                     <Typography variant="h5">{spec.icon}</Typography>
//                     <Typography variant="body2">{spec.label}</Typography>
//                   </Box>
//                 </Grid>
//               ))}
//             </Grid>

//             <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{ padding: "10px 20px", flex: 1, mr: 1 }}
//                 onClick={handleBuyNow}
//               >
//                 Buy Now
//               </Button>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 sx={{ padding: "10px 20px", flex: 1, ml: 1 }}
//                 onClick={handleTryNow}
//               >
//                 Try Now
//               </Button>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>

//       <ResponsiveSliderPage 
//         sliderImages={productDetails.media_by_category.task.map((media, index) => ({
//           url: decryptURI(media.media_url),
//           alt: `Slide ${index + 1}`,
//         }))}
//       />
//       <ProductDescriptionPage descdata={productDetails.level.key_description} />
//       <FeaturesPage Featuresimage={productDetails.media_by_category} />
//     </Box>
//   );
// };

// export default ProductDetailsPage;




import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Card, CardMedia, Button, useMediaQuery } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { PlayCircleOutline } from "@mui/icons-material";
import { fetchProductDetails } from "../../features/products/purchaseSlice"; // Redux slice
import ResponsiveSliderPage from "./Taskslider";
import ProductDescriptionPage from "./ProductDescriptionPage";
import FeaturesPage from "./FeaturesPage";
import decryptURI from "../modifier/decryptURI";
import StarRating from "./Rating";

const ProductDetailsPage = () => {
  const { levelCode } = useParams(); // Get levelCode from URL
  const { state } = useLocation(); // Get the state passed via navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ln = state.levelName;
  const lc = state.levelCode;
  useEffect(() => {
    console.log("Level Code from URL:", levelCode); // Print levelCode to the console
    console.log("Level state from URL:", state); // Print levelCode to the console
  }, [levelCode]); // Dependency to re-run the effect when levelCode changes
  const { productDetails, status, error } = useSelector((state) => state.productDetails);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [showAll, setShowAll] = useState(false); // For toggling key features
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (state) {
      dispatch(fetchProductDetails(state.levelCode)); // Fetch product details using levelCode
    }
  }, [dispatch, state]);

  useEffect(() => {
    console.log("State from navigate:", state); // Print the state object for debugging
  }, [state]);

  const handleThumbnailClick = (index) => {
    setSelectedMediaIndex(index);
  };

  const renderMainMedia = (media) => {
    if (media?.type === "image") {
      return (
        <CardMedia
          component="img"
          src={decryptURI(media.url)}
          alt="Main Media"
          sx={{ width: "100%", height: "400px", objectFit: "contain" }}
        />
      );
    }
    return (
      <iframe
        src={media?.url}
        title="Video"
        width="100%"
        height="400px"
        allowFullScreen
        style={{ border: "none" }}
      />
    );
  };

  const handleBuyNow = () => {
    const token = localStorage.getItem("token"); // Check if the user is logged in
    if (token) {
      // If logged in, navigate to /checkout with state
      navigate(`/checkout`, {
        state: {
          productName: productDetails.level.level_name,
          productCode: productDetails.level.level_code,
          productPrice: productDetails.level.price,
          productDiscount: productDetails.level.discount,
          productDescription: productDetails.level.key_description,
          productMedia: productDetails.media_by_category.box,
          productSpecifications: productDetails.specifications,
          productLevel: productDetails.level,
          productTitle: productDetails.title,
        },
      });
    } else {
      // If not logged in, redirect to login and pass the current page route as `from`
      navigate(`/login`, {
        state: { from: location.pathname }, // Save the current page the user was on
      });
    }
  };

  const handleTryNow = () => {
    console.log(state.levelName)
    const levelNameWithHyphens = ln.replace(/\s+/g, '-');
    navigate(`/tasks/${levelNameWithHyphens}`,{state:{levelCode:lc,levelName:ln}});
  };
  

  if (status === "loading") {
    return <Typography>Loading product details...</Typography>;
  }

  if (status === "failed" || error) {
    return <Typography>Error: {error || "Failed to load product details."}</Typography>;
  }

  if (!productDetails) {
    return <Typography>No product details available.</Typography>;
  }

  // Determine the key features to display
  const featuresToDisplay = showAll
    ? productDetails.level.key_features
    : productDetails.level.key_features.slice(0, 4);

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid container spacing={2}>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {/* Left-Left Section: Thumbnails */}
            {!isMobile && productDetails.media_by_category?.box && (
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    maxHeight: "400px",
                    gap: 1,
                  }}
                >
                  {productDetails.media_by_category.box.map((media, index) => {
                    const imageUrl = decryptURI(media.media_url);
                    return (
                      <Card
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                        sx={{
                          cursor: "pointer",
                          border: selectedMediaIndex === index ? "2px solid #3498db" : "1px solid #ccc",
                        }}
                      >
                        {media.media_type === "image" ? (
                          <CardMedia
                            component="img"
                            src={imageUrl} // Use the decrypted URL
                            alt={`Thumbnail ${index}`}
                            sx={{ height: "80px", objectFit: "cover" }}
                          />
                        ) : (
                          <Box sx={{ position: "relative" }}>
                            <CardMedia
                              component="img"
                              src="/images/video-placeholder.jpg"
                              alt="Video Placeholder"
                              sx={{ height: "80px", objectFit: "cover" }}
                            />
                            <PlayCircleOutline
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "white",
                                fontSize: 24,
                              }}
                            />
                          </Box>
                        )}
                      </Card>
                    );
                  })}
                </Box>
              </Grid>
            )}

            {/* Left-Right Section: Large Media Preview */}
            <Grid item xs={isMobile ? 12 : 9}>
              {isMobile ? (
                <SwipeableViews
                  index={selectedMediaIndex}
                  onChangeIndex={(index) => setSelectedMediaIndex(index)}
                >
                  {productDetails.media_by_category.box.map((media, index) => (
                    <Box key={index} sx={{ width: "100%" }}>
                      <CardMedia
                        component="img"
                        src={decryptURI(media.media_url)} // Use the decrypted URL
                        sx={{ height: "100%", objectFit: "cover" }}
                      />
                    </Box>
                  ))}
                </SwipeableViews>
              ) : (
                <Box>
                  <CardMedia
                    component="img"
                    src={decryptURI(productDetails.media_by_category.box[selectedMediaIndex].media_url)} // Use the decrypted URL
                    sx={{ height: "100%", objectFit: "cover" }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h4" gutterBottom>
              STEM LEARNING KIT- {productDetails.level.level_name}
            </Typography>
            <StarRating rating={4.8} />

            <Typography variant="h6" gutterBottom>
              Key Features:
            </Typography>
            <ul>
              {productDetails.level.key_features && Array.isArray(productDetails.level.key_features) ? (
                featuresToDisplay.map((feature, index) => (
                  <li key={index}>
                    <Typography variant="body1">{feature}</Typography>
                  </li>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No key features available.
                </Typography>
              )}
            </ul>

            {productDetails.level.key_features && productDetails.level.key_features.length > 4 && (
              <Button variant="text" onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Show Less' : 'Read More'}
              </Button>
            )}

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="h5" color="primary" sx={{ mr: 2, fontSize: "40px" }}>
                ₹{Math.round(productDetails.level.price - (productDetails.level.price * (productDetails.level.discount || 0)) / 100)} {/* Discounted Price */}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ textDecoration: "line-through", mr: 2, fontSize: "30px" }}>
                ₹{productDetails.level.price} {/* Original Price */}
              </Typography>
              <Typography variant="body1" color="green" sx={{ mr: 2, fontSize: "20px" }}>
                {Math.round(productDetails.level.discount)}% off
              </Typography>
            </Box>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {productDetails.specifications?.map((spec, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <Box textAlign="center">
                    <Typography variant="h5">{spec.icon}</Typography>
                    <Typography variant="body2">{spec.label}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ padding: "10px 20px", flex: 1, mr: 1 }}
                onClick={handleBuyNow}
              >
                Buy Now
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: "10px 20px", flex: 1, ml: 1 }}
                onClick={handleTryNow}
              >
                Try Now
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ResponsiveSliderPage 
        sliderImages={productDetails.media_by_category.task.map((media, index) => ({
          url: decryptURI(media.media_url),
          alt: `Slide ${index + 1}`,
        }))}
      />
      <ProductDescriptionPage descdata={productDetails.level.key_description} />
      <FeaturesPage Featuresimage={productDetails.media_by_category} />
    </Box>
  );
};

export default ProductDetailsPage;
