import React, { useRef, useState } from "react";
import "./VideoCardsPage.css"
import vid from"../../assets/videos/mov_bbb.mp4"
import ban from "../../assets/images/robocar.png"

const VideoCardPage = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="video-overlay-page">
      {/* Video Section */}
      <div className="video-container" onClick={togglePlayPause}>
        <video
          ref={videoRef}
          className="background-video"
          autoPlay
          loop
          muted
        >
          <source
            src={vid} // Replace with actual video path
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay-image">
          <img
            src={ban} // Replace with actual overlay image path
            alt="Overlay"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCardPage;
