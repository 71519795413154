import React from "react";
import Home from "./Home/Home";
import Games from "./Games/Games";
import About from "./About/About";
import Contact from "./Contact/Contact";


const LandingPage = () => {
  return (
    <div>
      <Home />
      <Games />
      <About />
      <Contact />
      
    </div>
  );
};

export default LandingPage;
