import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchTasksByLevelCode } from "../../features/products/taskSlice";
import TaskCard from "./TaskCard";
import { Box, Grid, Typography } from "@mui/material";


const TasksPage = () => {
  const { levelCode } = useParams(); // Get the level code from the URL
  const { state } = useLocation();
  
  const dispatch = useDispatch();
  const { tasks, status, error } = useSelector((state) => state.tasks);

  useEffect(() => {
    
    dispatch(fetchTasksByLevelCode(state.levelCode)); // Fetch tasks on component load
    
  }, [dispatch, levelCode]);

  if (status === "loading") {
    return <Typography>Loading tasks...</Typography>;
  }

  if (status === "failed") {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        variant="h4"
        textAlign="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Exploration Tasks: {levelCode}
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {tasks.map((task) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={task.id}>
            <TaskCard task={task} />
          </Grid>
        ))}
      </Grid>
      
    </Box>
  );
};

export default TasksPage;
