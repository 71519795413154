// import { configureStore } from '@reduxjs/toolkit';
// import authReducer from './features/auth/authSlice';
// import gameReducer from "./features/games/gameSlice"
// import productsReducer from "./features/products/productsSlice";
// import taskReducer from "./features/products/taskSlice"
// import productDetailsReducer from "./features/products/purchaseSlice"
// import promotedLevelsReducer from "./features/products/promotedLevelsSlice" 
// import contactusReducer from "./features/products/contactusSlice"
// import  taskDetailsReducer from './features/products/taskdetailsSlice';
// const store = configureStore({
//   reducer: {
//     auth: authReducer, // Authentication reducer
//     games: gameReducer,
//     products: productsReducer,
//     tasks: taskReducer,
//     productDetails: productDetailsReducer,
//     promotedLevels: promotedLevelsReducer,
//     contactus: contactusReducer,
//     taskDetails: taskDetailsReducer,
    
    
//   },
// });

// export default store;


import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import gameReducer from "./features/games/gameSlice";
import productsReducer from "./features/products/productsSlice";
import taskReducer from "./features/products/taskSlice";
import productDetailsReducer from "./features/products/purchaseSlice";
import promotedLevelsReducer from "./features/products/promotedLevelsSlice";
import contactusReducer from "./features/products/contactusSlice";
import taskDetailsReducer from './features/products/taskdetailsSlice';
import razorpayReducer from './features/products/razorpaySlice';
import addressReducer from './features/products/addressReducer'; 

// Configure the store
const store = configureStore({
  reducer: {
    auth: authReducer, // Authentication reducer
    games: gameReducer,
    products: productsReducer,
    tasks: taskReducer,
    productDetails: productDetailsReducer,
    promotedLevels: promotedLevelsReducer,
    contactus: contactusReducer,
    taskDetails: taskDetailsReducer,
    address: addressReducer, // Add the address reducer here
    razorpay: razorpayReducer,
  },
});

export default store;
