// import React, { useState } from "react";
// import styles from "./ThemeSwitcher.module.css"; // Import CSS module
// import Card from "../Card/Card"; // Import the previously created card component
// import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
// import { Navigation, Pagination } from "swiper/modules"; // Import Navigation and Pagination modules
// import "swiper/css"; // Swiper core styles
// import "swiper/css/navigation"; // Swiper navigation styles
// import "swiper/css/pagination"; // Swiper pagination styles

// const themes = {
//   battleField: {
//     backgroundImage: "../../assets/images/b1.jpg",
//     products: [
//       {
//         id: 1,
//         title: "Robo Car",
//         description: "STEM Learning Kit for Battle Field Theme",
//         currentPrice: "Rs. 3499/-",
//         oldPrice: "Rs. 3999",
//         rating: "4.8",
//         offers: "10% Off",
//         image: "https://via.placeholder.com/300x200",
//       },
//       {
//         id: 2,
//         title: "Military Jeep",
//         description: "STEM Learning Kit for Battle Field Theme",
//         currentPrice: "Rs. 3999/-",
//         oldPrice: "Rs. 4599",
//         rating: "4.9",
//         offers: "15% Off",
//         image: "https://via.placeholder.com/300x200",
//       },
//     ],
//   },
//   smartCity: {
//     backgroundImage: "url('https://via.placeholder.com/1920x800?text=Smart+City')",
//     products: [
//       {
//         id: 3,
//         title: "Smart Car",
//         description: "STEM Learning Kit for Smart City Theme",
//         currentPrice: "Rs. 4599/-",
//         oldPrice: "Rs. 4999",
//         rating: "4.7",
//         offers: "20% Off",
//         image: "https://via.placeholder.com/300x200",
//       },
//     ],
//   },
// };

// const ThemeSwitcher = () => {
//   const [selectedTheme, setSelectedTheme] = useState("battleField");

//   const currentTheme = themes[selectedTheme];

//   return (
//     <div className={styles.themeContainer}>
//       {/* Theme Buttons */}
//       <div className={styles.themeButtons}>
//         <button
//           className={`${styles.themeButton} ${
//             selectedTheme === "battleField" ? styles.active : ""
//           }`}
//           onClick={() => setSelectedTheme("battleField")}
//         >
//           Battle Field
//         </button>
//         <button
//           className={`${styles.themeButton} ${
//             selectedTheme === "smartCity" ? styles.active : ""
//           }`}
//           onClick={() => setSelectedTheme("smartCity")}
//         >
//           Smart City
//         </button>
//       </div>

//       {/* Banner Section */}
//       <div
//         className={styles.bannerSection}
//         style={{
//           backgroundImage: currentTheme.backgroundImage,
//         }}
//       >
//         <Swiper
//           spaceBetween={20}
//           slidesPerView={1}
//           breakpoints={{
//             768: { slidesPerView: 2 },
//             1024: { slidesPerView: 3 },
//           }}
//           navigation
//           pagination={{ clickable: true }}
//           modules={[Navigation, Pagination]}
//           className={styles.cardsSwiper}
//         >
//           {currentTheme.products.map((product) => (
//             <SwiperSlide key={product.id} className={styles.swiperSlide}>
//               <Card
//                 title={product.title}
//                 description={product.description}
//                 currentPrice={product.currentPrice}
//                 oldPrice={product.oldPrice}
//                 rating={product.rating}
//                 offers={product.offers}
//                 image={product.image}
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ThemeSwitcher;



// import React, { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
// import { Navigation, Pagination } from "swiper/modules"; // Import Navigation and Pagination modules
// import "swiper/css"; // Swiper core styles
// import "swiper/css/navigation"; // Swiper navigation styles
// import "swiper/css/pagination"; // Swiper pagination styles
// import Card from "../Card/Card"; // Assuming Card component exists
// import decryptURI from "../modifier/decryptURI";
// import ban from "../../assets/images/deskban1.jpg"

// const ThemeSwitcher = ({ promotedLevels }) => {
//   const [selectedTheme, setSelectedTheme] = useState("battleField");

//   // Determine the theme based on the selectedTheme state
//   const currentTheme = selectedTheme === "battleField" ? 1 : 2; // 1 for BattleField, 2 for Smart City

//   // Filter the promoted products based on the current theme
//   const filteredProducts = promotedLevels.filter((product) => product.game === currentTheme);

//   return (
//     <div>
//       {/* Theme Buttons */}
//       <div>
//         <button onClick={() => setSelectedTheme("battleField")}>Battle Field</button>
//         <button onClick={() => setSelectedTheme("smartCity")}>Smart City</button>
//       </div>

//       {/* Banner Section */}
//       <div style={{ background: {ban}, height: "500px" }}>
//         <Swiper
//           spaceBetween={20}
//           slidesPerView={1}
//           breakpoints={{
//             768: { slidesPerView: 2 },
//             1024: { slidesPerView: 3 },
//           }}
//           navigation
//           pagination={{ clickable: true }}
//           modules={[Navigation, Pagination]}
//         >
//           {filteredProducts.map((product) => (
//             <SwiperSlide key={product.id}>
//               <Card
//                 title={product.level_name}
//                 description={product.level_title}
//                 currentPrice={product.price}
//                 oldPrice={`Rs. ${Math.round(product.price - (product.price * (product.discount || 0)) / 100)}`}
//                 rating={4.8}
//                 image={decryptURI(product.photo)} // Assuming 'photo' is the image URL
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ThemeSwitcher;


import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import { Navigation, Pagination } from "swiper/modules"; // Import Navigation and Pagination modules
import "swiper/css"; // Swiper core styles
import "swiper/css/navigation"; // Swiper navigation styles
import "swiper/css/pagination"; // Swiper pagination styles
import Card from "../Card/Card"; // Assuming Card component exists
import decryptURI from "../modifier/decryptURI";
import { useParams, useNavigate } from "react-router-dom";
// import ban from "../../assets/images/deskban1.jpg"; // Default background image
import btlf from "../../assets/images/battle_field.png"
import sc from "../../assets/images/smart_city.png"

const ThemeSwitcher = ({ promotedLevels }) => {
  const [selectedTheme, setSelectedTheme] = useState("battleField");
  const navigate = useNavigate(); // For navigation

  // Set background image based on selected theme
  const themeBackground = selectedTheme === "battleField" ? `url(${btlf})` : `url(${sc})`;

  // Filter the promoted products based on the current theme
  const currentTheme = selectedTheme === "battleField" ? 1 : 2; // 1 for BattleField, 2 for Smart City
  const filteredProducts = promotedLevels.filter((product) => product.game === currentTheme);
  const handleTryNow = (levelCode) => {
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');
    navigate(`/tasks/${levelNameWithHyphens}`,{state:{
      levelName: levelCode.level_name, 
      levelCode: levelCode.level_code
  }}); // Navigate to tasks page
  };

  const handleBuyNow = (levelCode) => {
    // Dispatch an action to initiate purchase
    // dispatch(fetchProductDetails(levelCode.level_code));
    
    // Replace spaces with hyphens in level_name
    const levelNameWithHyphens = levelCode.level_name.replace(/\s+/g, '-');

    // Navigate to purchase page with modified levelName
    navigate(`/stem-learning-kit/${levelNameWithHyphens}`, {
        state: {
            levelName: levelCode.level_name, 
            levelCode: levelCode.level_code
        }
    });
};

  return (
    <div style={{ position: "relative" }}>
      {/* Theme Buttons */}
      <div style={{ textAlign: "center", margin: "50px" ,}}>
      <button
  style={{
    padding: "15px 25px",
    marginRight: "20px",
    backgroundColor: selectedTheme === "battleField" ? "#007aff" : "#f0f0f0",
    color: selectedTheme === "battleField" ? "white" : "#555",
    border: "2px solid #007aff",
    borderRadius: "50px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    transition: "all 0.3s ease",
    boxShadow: selectedTheme === "battleField" ? "0px 4px 15px rgba(0, 122, 255, 0.5)" : "none",
    transform: selectedTheme === "battleField" ? "scale(1.1)" : "scale(1)",
    animation: selectedTheme === "battleField" ? "wiggle 0.5s infinite alternate" : "none",
  }}
  onClick={() => setSelectedTheme("battleField")}
>
  🚀BattleField
</button>

<button
  style={{
    padding: "15px 25px",
    backgroundColor: selectedTheme === "smartCity" ? "#ffcc00" : "#f0f0f0",
    color: selectedTheme === "smartCity" ? "white" : "#555",
    border: "2px solid #ffcc00",
    borderRadius: "50px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    transition: "all 0.3s ease",
    boxShadow: selectedTheme === "smartCity" ? "0px 4px 15px rgba(255, 204, 0, 0.5)" : "none",
    transform: selectedTheme === "smartCity" ? "scale(1.1)" : "scale(1)",
    animation: selectedTheme === "smartCity" ? "bounce 0.5s infinite alternate" : "none",
  }}
  onClick={() => setSelectedTheme("smartCity")}
>
  🌆 Smart City
</button>







      </div>

      {/* Banner Section */}
      <div
        style={{
          background: themeBackground,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
        }}
      >
        {/* Overlay for cards */}
        <div
          style={{
            position: "absolute",
            top: "90%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            padding: "20px",
          }}
        >
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            navigation
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            style={{ padding: "20px" }}
          >
            {filteredProducts.map((product) => (
              <SwiperSlide key={product.id}>
                <Card
                  title={product.level_name}
                  description={product.level_title}
                  currentPrice={product.price}
                  oldPrice={`Rs. ${Math.round(product.price - (product.price * (product.discount || 0)) / 100)}`}
                  rating={4.8}
                  image={decryptURI(product.photo)} // Assuming 'photo' is the image URL
                  onTryNow={() => handleTryNow(product)} // Pass level_code to handler
                onBuyNow={() => handleBuyNow(product)} // Pass level_code for purchase
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
    
  );
  
};


export default ThemeSwitcher;



// import React, { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import Card from "../Card/Card"; // Assuming Card component exists
// import decryptURI from "../modifier/decryptURI";
// import { useNavigate } from "react-router-dom";
// import btlf from "../../assets/images/battle_field.png";
// import sc from "../../assets/images/smart_city.png";

// const ThemeSwitcher = ({ promotedLevels }) => {
//   const [selectedTheme, setSelectedTheme] = useState("battleField");
//   const navigate = useNavigate();

//   const themeBackground = selectedTheme === "battleField" ? `url(${btlf})` : `url(${sc})`;

//   const currentTheme = selectedTheme === "battleField" ? 1 : 2; // 1 for BattleField, 2 for Smart City
//   const filteredProducts = promotedLevels.filter((product) => product.game === currentTheme);

//   const handleTryNow = (levelCode) => {
//     navigate(`/tasks/${levelCode}`);
//   };

//   const handleBuyNow = (levelCode) => {
//     navigate(`/stem-learning-kit/${levelCode}`);
//   };

//   const toggleTheme = () => {
//     setSelectedTheme((prev) => (prev === "battleField" ? "smartCity" : "battleField"));
//   };

//   return (
//     <div style={{ position: "relative" }}>
//       {/* Theme Toggle */}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           margin: "50px 0",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: selectedTheme === "battleField" ? "#FF8C00" : "#4169E1",
//             borderRadius: "30px",
//             padding: "5px",
//             width: "250px",
//             height: "50px",
//             position: "relative",
//             cursor: "pointer",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//             transition: "background 0.3s ease-in-out",
//           }}
//           onClick={toggleTheme}
//         >
//           {/* Text Inside Button */}
//           <div
//             style={{
//               flex: 1,
//               textAlign: "center",
//               fontSize: "16px",
//               fontWeight: "bold",
//               color: "#fff",
//               transition: "color 0.3s ease-in-out",
//             }}
//           >
//             {selectedTheme === "battleField" ? "BattleField" : "Smart City"}
//           </div>

//           {/* Movable Emoji Knob */}
//           <div
//             style={{
//               position: "absolute",
//               top: "10px",
//               left: selectedTheme === "battleField" ? "5px" : "calc(100% - 45px)",
//               width: "40px",
//               height: "40px",
//               background: "#fff",
//               borderRadius: "50%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               fontSize: "20px",
//               color: selectedTheme === "battleField" ? "#FF8C00" : "#4169E1",
//               boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
//               transition: "all 0.3s ease-in-out",
//             }}
//           >
//             {selectedTheme === "battleField" ? "🚀" : "🌆"}
//           </div>
//         </div>
//       </div>

//       {/* Banner Section */}
//       <div
//         style={{
//           background: themeBackground,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           height: "500px",
//           position: "relative",
//         }}
//       >
//         {/* Overlay for cards */}
//         <div
//           style={{
//             position: "absolute",
//             top: "90%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "100%",
//             padding: "20px",
//           }}
//         >
//           <Swiper
//             spaceBetween={20}
//             slidesPerView={1}
//             breakpoints={{
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//             navigation
//             pagination={{ clickable: true }}
//             modules={[Navigation, Pagination]}
//             style={{ padding: "20px" }}
//           >
//             {filteredProducts.map((product) => (
//               <SwiperSlide key={product.id}>
//                 <Card
//                   title={product.level_name}
//                   description={product.level_title}
//                   currentPrice={product.price}
//                   oldPrice={`Rs. ${Math.round(product.price - (product.price * (product.discount || 0)) / 100)}`}
//                   rating={4.8}
//                   image={decryptURI(product.photo)}
//                   onTryNow={() => handleTryNow(product.level_code)}
//                   onBuyNow={() => handleBuyNow(product.level_code)}
//                 />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ThemeSwitcher;


// import React, { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Autoplay } from "swiper/modules"; // Import Pagination and Autoplay
// import "swiper/css";
// import "swiper/css/pagination";
// import Card from "../Card/Card"; // Assuming Card component exists
// import decryptURI from "../modifier/decryptURI";
// import { useNavigate } from "react-router-dom";
// import btlf from "../../assets/images/battle_field.png";
// import sc from "../../assets/images/smart_city.png";

// const ThemeSwitcher = ({ promotedLevels }) => {
//   const [selectedTheme, setSelectedTheme] = useState("battleField");
//   const navigate = useNavigate();

//   const themeBackground = selectedTheme === "battleField" ? `url(${btlf})` : `url(${sc})`;

//   const currentTheme = selectedTheme === "battleField" ? 1 : 2; // 1 for BattleField, 2 for Smart City
//   const filteredProducts = promotedLevels.filter((product) => product.game === currentTheme);

//   const handleTryNow = (levelCode) => {
//     navigate(`/tasks/${levelCode}`);
//   };

//   const handleBuyNow = (levelCode) => {
//     navigate(`/stem-learning-kit/${levelCode}`);
//   };

//   const toggleTheme = () => {
//     setSelectedTheme((prev) => (prev === "battleField" ? "smartCity" : "battleField"));
//   };

//   return (
//     <div style={{ position: "relative" }}>
//       {/* Theme Toggle */}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           margin: "50px 0",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: selectedTheme === "battleField" ? "#FF8C00" : "#4169E1",
//             borderRadius: "30px",
//             padding: "5px",
//             width: "250px",
//             height: "50px",
//             position: "relative",
//             cursor: "pointer",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//             transition: "background 0.3s ease-in-out",
//           }}
//           // onClick={toggleTheme}
//         >
//           {/* Text Inside Button */}
//           <div
//             style={{
//               flex: 1,
//               textAlign: "center",
//               fontSize: "16px",
//               fontWeight: "bold",
//               color: "#fff",
//               transition: "color 0.3s ease-in-out",
//             }}
//           >
//             {selectedTheme === "battleField" ? "BattleField" : "Smart City"}
//           </div>

//           {/* Movable Emoji Knob */}
//           <div
//   style={{
//     position: "absolute",
//     top: "10px",
//     left: selectedTheme === "battleField" ? "5px" : "calc(100% - 45px)",
//     width: "40px",
//     height: "40px",
//     background: "#fff",
//     borderRadius: "50%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "20px",
//     color: selectedTheme === "battleField" ? "#FF8C00" : "#4169E1",
//     boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
//     transition: "all 0.3s ease-in-out",
//     animation: `${
//       selectedTheme === "battleField" ? "wiggleRight" : "wiggleLeft"
//     } 1s infinite`,
//     cursor: "pointer", // Ensures it visually looks clickable
//   }}
//   onClick={toggleTheme} // Ensures the onClick handler works
// >
//   {selectedTheme === "battleField" ? "🚀" : "🌆"}
// </div>

// <style>
//   {`
//     @keyframes wiggleLeft {
//       0%, 100% {
//         transform: translateX(0);
//       }
//       50% {
//         transform: translateX(-5px); /* Moves slightly left */
//       }
//     }

//     @keyframes wiggleRight {
//       0%, 100% {
//         transform: translateX(0);
//       }
//       50% {
//         transform: translateX(5px); /* Moves slightly right */
//       }
//     }
//   `}
// </style>

//         </div>
//       </div>

//       {/* Banner Section */}
//       <div
//         style={{
//           background: themeBackground,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           height: "500px",
//           position: "relative",
//         }}
//       >
//         {/* Overlay for cards */}
//         <div
//           style={{
//             position: "absolute",
//             top: "90%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "100%",
//             padding: "20px",
//           }}
//         >
//           <Swiper
//             spaceBetween={20}
//             slidesPerView={1}
//             breakpoints={{
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//             autoplay={{
//               delay: 3000, // Set delay to 3 seconds
//               disableOnInteraction: false, // Continue autoplay even after user interaction
//             }}
//             // pagination={{ clickable: true }}
//             modules={[Pagination, Autoplay]} // Use Pagination and Autoplay modules
//             loop={true} // Enable infinite looping
//             style={{ padding: "20px" }}
//           >
//             {filteredProducts.map((product) => (
//               <SwiperSlide key={product.id}>
//                 <Card
//                   title={product.level_name}
//                   description={product.level_title}
//                   oldPrice={product.price}
//                   currentPrice={`Rs. ${Math.round(product.price - (product.price * (product.discount || 0)) / 100)}`}
//                   rating={4.8}
//                   image={decryptURI(product.photo)}
//                   onTryNow={() => handleTryNow(product.level_code)}
//                   onBuyNow={() => handleBuyNow(product.level_code)}
//                 />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ThemeSwitcher;
