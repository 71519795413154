import React from "react";
import "./ServicesPages.css";
import im1 from "../../assets/images/ILEARNPLACE STEM & Robotics LAB in School.webp"; // Replace with your actual image path
import im2 from "../../assets/images/STEM at home.webp"; // Replace with your actual image path
import im3 from "../../assets/images/iLearnPlace STEM & Robotics Club.webp"; // Replace with your actual image path

const ServicesPage = () => {
  const features = [
    {
      id: 1,
      title: "ILEARNPLACE STEM & Robotics LAB in School",
      description:"iLearnPlace's STEM & Robotics Labs in schools offer hands-on learning through interactive experiments and robotics projects. Designed to enhance creativity, problem-solving, and teamwork, our labs provide engaging, curriculum-aligned workshops. Empower students with practical skills and innovation for a brighter future.",
      image: im1,
    },
    {
      id: 2,
      title: "STEM at  Home",
      description: "Explore STEM at home with engaging activities and interactive resources. Inspire creativity, problem-solving, and hands-on learning in a fun and accessible way.",
      image: im2,
    },
    {
        id: 3,
        title: "iLearnPlace STEM & Robotics Club",
        description: "iLearnPlace STEM & Robotics Club offers opportunities to explore innovation, creativity, and hands-on learning. Participate in exciting offline projects, challenges, and collaborative experiences with peers.",
        image: im3,
      },
      
  ];

  return (
    <div className="features-container">
      <div className="features-header">
        <h1>
          Our <span className="highlight">Services</span>
        </h1>
        <p>These extraordinary features can make learning activities more efficient.</p>
      </div>

      {features.map((feature, index) => (
        <div
          className={`features-row ${index % 2 === 0 ? "normal" : "reverse"}`}
          key={feature.id}
        >
          <div className="features-image">
            <img src={feature.image} alt={feature.title} />
          </div>
          <div className="features-content">
            <h3>{feature.title}</h3>
            {Array.isArray(feature.description) ? (
              <ul>
                {feature.description.map((item, i) => (
                  <li key={i}>
                    <span className="icon">🔹</span> {item}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{feature.description}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesPage;
