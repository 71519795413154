import React from "react";
import "./FeaturesPage.css";
import im1 from "../../assets/images/Aligned with 21st Century Skills & NCF 2020.webp"
import im2 from "../../assets/images/Gamified STEM Learning Kits.webp"
import im3 from "../../assets/images/240+ Hands-On Science & Math Experiments.webp"
import im4 from "../../assets/images/60+ STEM Tasks with Step-by-Step Manuals.webp"
import im5 from "../../assets/images/ILP LMS Dashboard.webp"
import im6 from "../../assets/images/Gamified STEM Learning & Mind Development.webp"
import im7 from "../../assets/images/AI Coding Made Easy.webp"
import im8 from "../../assets/images/Kids Robotics.webp"
import im9 from "../../assets/images/Recorded Videos.webp"


const FeaturesPage = () => {
  const features = [
    {
      id: 1,
      title: "Aligned with 21st Century Skills & NCF 2020",
      description: "iLearnPlace’s curriculum aligns with the National Curriculum Framework (NCF) 2020, helping students develop essential 21st-century skills like problem-solving, collaboration, and creativity. Each activity is designed to complement academic learning and prepare children for the future.",
      image: im1,
    },
    {
      id: 2,
      title: "Gamified STEM Learning Kits",
      description: "The gamified learning kits are the cornerstone of iLearnPlace. These kits offer interactive, hands-on experiences that encourage students to experiment, build, and innovate. The kits are available in themed levels, such as Battlefield (Military Jeep, RC Robo Car) and Smart City (Smart Water Tank, Shining House, Railway Crossing), allowing children to explore real-world engineering and technology applications.",
      image: im2,
    },
    {
        id: 3,
        title: "240+ Hands-On Science & Math Experiments",
        description: "With over 240 experiments, iLearnPlace brings science and math to life. Students explore fundamental principles of physics, electronics, mechanics, and more through practical activities that solidify their understanding.",
        image: im3,
      },
      {
        id: 4,
        title: "60+ STEM Tasks with Step-by-Step Manuals",
        description: "iLearnPlace offers detailed, easy-to-follow manuals for over 60 STEM tasks, empowering children to work independently while building confidence and mastering skills.",
        image: im4,
      },
      {
        id: 5,
        title: "ILP LMS Dashboard",
        description: "The AI-enabled LMS Dashboard acts as a digital playground where students can bring theoretical concepts into reality. It provides personalized learning experiences, gamified progress tracking, and a seamless way to integrate hands-on tasks with digital tools.",
        image: im5,
      },
      {
        id: 6,
        title: "Gamified STEM Learning & Mind Development",
        description: "By incorporating game-based activities, iLearnPlace boosts engagement, critical thinking, and cognitive development. Students stay motivated and curious, fostering a love for science, technology, engineering, and mathematics.",
        image: im6,
      },
      {
        id: 7,
        title: "AI Coding Made Easy",
        description: "Coding is simplified through intuitive modules that introduce children to programming and AI concepts. From basic coding principles to creating AI-powered projects, the platform makes these advanced topics accessible and exciting.",
        image: im7,
      },
      {
        id: 8,
        title: "Kids Robotics",
        description: "iLearnPlace introduces robotics in a fun and approachable way. Children can design, build, and program robots, gaining valuable skills in automation, electronics, and control systems.",
        image: im8,
      },
      {
        id: 9,
        title: "Recorded Videos",
        description: "Pre-recorded video tutorials serve as a step-by-step guide, helping students independently navigate experiments, projects, and advanced concepts. These videos enhance the learning experience and act as a reliable reference.",
        image: im9,
      },
  ];

  return (
    <div className="features-container">
      <div className="features-header">
        <h1>
          Our <span className="highlight">Features</span>
        </h1>
        <p>These extraordinary features can make learning activities more efficient.</p>
      </div>

      {features.map((feature, index) => (
        <div
          className={`features-row ${index % 2 === 0 ? "normal" : "reverse"}`}
          key={feature.id}
        >
          <div className="features-image">
            <img src={feature.image} alt={feature.title} />
          </div>
          <div className="features-content">
            <h3>{feature.title}</h3>
            {Array.isArray(feature.description) ? (
              <ul>
                {feature.description.map((item, i) => (
                  <li key={i}>
                    <span className="icon">🔹</span> {item}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{feature.description}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturesPage;
