import React from "react";
import "./ShopWithPage.css";
import walmartLogo from "../../assets/images/walmart.png"; // Replace with actual logo path
import amazonLogo from "../../assets/images/amazon.png"; // Replace with actual logo path
import flipkartLogo from "../../assets/images/flipkart.png"; // Replace with actual logo path

const ShopWithPage = () => {
  return (
    <div className="shop-with-container">
      <h2 className="shop-with-title">
        Shop <span className="highlight">With</span>
      </h2>
      <div className="shop-with-box">
        <div className="shop-with-logo">
          <img src={walmartLogo} alt="Walmart" />
        </div>
        <div className="shop-with-logo">
          <img src={amazonLogo} alt="Amazon" />
        </div>
        <div className="shop-with-logo">
          <img src={flipkartLogo} alt="Flipkart" />
        </div>
      </div>
    </div>
  );
};

export default ShopWithPage;
