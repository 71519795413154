import React from "react";
import { Box, Fab, Typography } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp"; // Download icon

const DownloadAppButton = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 80, // Positioned above the WhatsApp button
        right: 16,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#007aff", // Blue color
        color: "white",
        padding: "10px",
        borderRadius: "25px",
        boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 4px 20px rgba(0,0,0,0.3)",
        },
      }}
      onClick={() => window.open("https://play.google.com/store/apps/details?id=com.iLearnPlace.stemlearning&pcampaignid=web_shar", "_blank")} // Replace with your app download link
    >
      <GetAppIcon />
      <Typography variant="body2" sx={{ ml: 1 }}>
        Download App
      </Typography>
    </Box>
  );
};

export default DownloadAppButton;
