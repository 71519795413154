import React from "react";

const CardFlip = (datas) => {
  // Data to be displayed on the cards
  const cardData = [
    { title: "Science", desc: datas.data.tasks[0].task[0].engineering, bgColor: "linear-gradient(135deg, #ff9a9e, #fad0c4)" },
    { title: "Math", desc: datas.data.tasks[0].task[0].maths, bgColor: "linear-gradient(135deg, #a18cd1, #fbc2eb)" },
    { title: "Technology", desc: datas.data.tasks[0].task[0].technology, bgColor: "linear-gradient(135deg, #fbc2eb, #a6c1ee)" },
    { title: "Programming", desc: datas.data.tasks[0].task[0].programming_skill, bgColor: "linear-gradient(135deg, #ffecd2, #fcb69f)" },
  ];

  return (
    <div style={styles.cardContainer}>
      {cardData.map((data, index) => (
        <div key={index} style={{ ...styles.card, background: data.bgColor }}>
          <h3 style={styles.cardTitle}>{data.title}</h3>
          <p style={styles.cardDescription}>{data.desc}</p>
        </div>
      ))}
    </div>
  );
};

// Inline CSS styles
const styles = {
  cardContainer: {
    display: "flex",
    flexWrap: "wrap", // Allows wrapping of cards
    justifyContent: "center", // Center-align cards horizontally
    gap: "20px", // Space between cards
    marginTop: "50px",
    padding: "0 20px",
    width: "100%",
  },
  card: {
    flex: "1 1 calc(50% - 20px)", // Two cards per row
    maxWidth: "calc(50% - 20px)", // Prevent cards from exceeding the width
    height: "180px",
    borderRadius: "16px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    color: "white", // White text for contrast with colorful backgrounds
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
    position: "relative",
    overflow: "hidden",
  },
  cardTitle: {
    margin: "0 0 10px 0",
    fontSize: "20px",
    fontWeight: "bold",
    textShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
  },
  cardDescription: {
    margin: 0,
    fontSize: "14px",
    lineHeight: "1.5",
    textShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
  },
  // Add a hover effect to cards
  cardHover: {
    "&:hover": {
      transform: "scale(1.05)", // Slightly enlarge the card
      boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)", // Intensify the shadow
    },
  },
  // Media Queries for Responsiveness
  "@media (max-width: 768px)": {
    card: {
      flex: "1 1 100%", // Take up full width on smaller screens
      maxWidth: "100%", // Ensure full width
    },
  },
};

export default CardFlip;
