// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchGames } from "../../features/games/gameSlice";
// import "./GamesPage.css";

// const GamesPage = () => {
//   const dispatch = useDispatch();
//   const { games, status, error } = useSelector((state) => state.games);

//   useEffect(() => {
//     if (status === "idle") {
//       dispatch(fetchGames());
//     }
//   }, [status, dispatch]);
// console.log(games)
//   return (
//     <div className="games-page">
//       <h1>Games Category</h1>
//       <p>
//         iLearnPlace offers innovative educational kits in robotics, electronics, AI, and IoT,
//         enabling students to build hands-on projects using high-quality components and
//         learn key STEM concepts.
//       </p>

//       {status === "loading" && <p>Loading games...</p>}
//       {status === "failed" && <p>Error: {error}</p>}
//       {status === "succeeded" && (
//         <div className="games-grid">
//           {games.map((game) => (
//             <div key={game.id} className="game-card">
//               <div className={`game-card-overlay ${game.is_locked ? "locked" : ""}`}>
//                 <img
//                   src={game.image_url}
//                   alt={game.title}
//                   className="game-card-image"
//                 />
//                 {game.is_locked && <div className="lock-icon">🔒</div>}
//               </div>
//               <p className="game-title">{game.title}</p>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default GamesPage;



// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { fetchGames } from "../../features/games/gameSlice";
// import decryptURI from "../../components/modifier/decryptURI"; // Import the decryptURI function
// import "./GamesPage.css";


// const GamesPage = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate(); // For navigation
//   const { games, status, error } = useSelector((state) => state.games);

//   // Allowed game types
//   const allowedGames = ["Takshak", "Totoka"];

//   useEffect(() => {
//     if (status === "idle") {
//       dispatch(fetchGames());
//     }
//   }, [status, dispatch]);

//   const handleCardClick = (game) => {
    
//     if (!allowedGames.includes(game.game_name)) {
//       alert("Coming Soon");
//     } else {
//       // Redirect to ProductPage with game_code as a parameter
//       navigate(`/levels/${game.game_code}`);
//     }
//   };

//   return (
//     <div className="games-page">
//       <h1>Explore Our Games</h1>
//       <p>
//       Explore our exciting categories of games: educational STEM challenges to enhance learning and fun robotics simulations to spark creativity.
//       </p>

//       {status === "loading" && <p>Loading games...</p>}
//       {status === "failed" && <p>Error: {error}</p>}
//       {status === "succeeded" && (
//         <div className="games-grid">
//           {games.map((game) => {
//             let imageUrl = game.image_url;
//             try {
//               imageUrl = decryptURI(game.image_url); // Decrypt the image URL
//             } catch (err) {
//               console.error("Failed to decrypt image URL:", err);
//             }

//             const isLocked = !allowedGames.includes(game.game_name);

//             return (
//               <div
//                 key={game.id}
//                 className="game-card"
//                 onClick={() => handleCardClick(game)}
//               >
//                 <div className={`game-card-overlay ${isLocked ? "locked" : ""}`}>
//                   <img
//                     src={imageUrl} // Use the decrypted image URL
//                     alt={game.game_type}
//                     className="game-card-image"
//                   />
//                   {isLocked && <div className="lock-icon">🔒</div>}
//                 </div>
//                 <p className="game-title">{game.game_type}</p>
//               </div>
//             );
//           })}
//         </div>
//       )}
      
//     </div>
//   );
// };

// export default GamesPage;


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchGames } from "../../features/games/gameSlice";
import decryptURI from "../../components/modifier/decryptURI";
import "./GamesPage.css";

const GamesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { games, status, error } = useSelector((state) => state.games);

  const allowedGames = ["Takshak", "Totoka"]; // Allowed game types

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchGames());
    }
  }, [status, dispatch]);

  const handleCardClick = (game) => {
    if (!allowedGames.includes(game.game_name)) {
      alert("Coming Soon");
    } else {
      navigate(`/levels/${game.game_code}`);
    }
  };

  return (
    <div className="games-page">
      <h1>Explore Our Games</h1>
      <p>
        Explore our exciting categories of games: educational STEM challenges to
        enhance learning and fun robotics simulations to spark creativity.
      </p>

      {status === "loading" && <p>Loading games...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div className="games-grid">
          {games.map((game) => {
            let imageUrl = game.image_url;
            try {
              imageUrl = decryptURI(game.image_url);
            } catch (err) {
              console.error("Failed to decrypt image URL:", err);
            }

            const isLocked = !allowedGames.includes(game.game_name);

            return (
              <div
                key={game.id}
                className="game-card"
                onClick={() => !isLocked && handleCardClick(game)}
              >
                <div
                  className={`game-card-overlay ${isLocked ? "locked" : ""}`}
                >
                  <img
                    src={imageUrl}
                    alt={game.game_type}
                    className="game-card-image"
                  />
                  {isLocked ? (
                    <div className="icon-overlay lock-icon">🔒</div>
                  ) : (
                    <div className="icon-overlay play-icon">▶️</div>
                  )}
                </div>
                <p className="game-title">{game.game_type}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GamesPage;
